import React, { useCallback, useContext, useState } from "react";
import {
  Input,
  Select,
  Button,
} from "antd";
import AgentDetailDrawer from "./AgentDetailDrawer";
import { AgentContext } from "./index";
import { SearchOutlined } from "@ant-design/icons";
import AgentQuotaSummary from './AgentQuotaSummary'

const { Option } = Select;

export default React.memo(
  ({
    filterInfo: { textFilter, statusFilter, quotaTypeFilter },
    onFilterChange,
  }) => {
    const { openDrawer } = useContext(AgentContext);
    const [isQuotaHistoriesVisible, setIsQuotaHistoriesVisible] = useState(false);

    function onTextFilterChange(event) {
      const textFilter = event.target.value;
      onFilterChange((f) => ({ ...f, textFilter }));
    }

    function onStatusFilterChange(statusFilter) {
      onFilterChange((f) => ({ ...f, statusFilter }));
    }

    function onQuotaTypeFilterChange(quotaTypeFilter) {
      onFilterChange((f) => ({ ...f, quotaTypeFilter }));
    }

    const showQuotaHistories = () => setIsQuotaHistoriesVisible(true);
    const hideQuotaHistories = useCallback(() => {
      setIsQuotaHistoriesVisible(false)
    }, [])

    return (
      <div className="row align-items-center no-gutters">
        <nav className="navbar navbar-expand-lg navbar-light w-100 pl-0">
          <button
            className="navbar-toggler navbar-toggler-hidden"
            type="button"
            data-toggle="collapse"
            data-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="feather-icon icon-list text-main icon-size-lottery"></i>
          </button>
          <div
            className="collapse navbar-collapse position-relative"
            id="navbarTogglerDemo02"
          >
            <div className="col-12 col-xl-3 col-lg-3">
              <div className="row ">
                <div className="col-12 col-lg-12 pl-lg-0 mt-4 mt-lg-0">
                  <div className="input-filter-all">
                    <Input
                      className="w-100 rounded-pill input-search"
                      value={textFilter}
                      allowClear
                      placeholder="ค้นหาข้อมูล"
                      onChange={onTextFilterChange}
                      suffix={textFilter ? undefined : ( <SearchOutlined className="icon-input-filter" />)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-3 col-sm-3 mt-4 my-sm-0">
              <div className="row no-gutters align-items-center">
                <div className="col-sm-auto mx-1">
                  <span className="white-space-nowrap text-gray text-small">
                    ประเภทโควตา :
                  </span>
                </div>
                <div className="col">
                  <Select
                    className="w-100"
                    value={quotaTypeFilter}
                    onChange={onQuotaTypeFilterChange}
                    allowClear
                  >
                    <Select.Option value="N"> ปกติ </Select.Option>
                    <Select.Option value="C"> เครดิต </Select.Option>
                  </Select>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-3 mt-4 my-sm-0">
              <div className="row no-gutters align-items-center">
                <div className="col-sm-auto mx-1">
                  <span className="white-space-nowrap text-gray text-small">
                    สถานะ :
                  </span>
                </div>
                <div className="col">
                  <Select
                    className="w-100"
                    value={statusFilter}
                    optionFilterProp="label"
                    placeholder="ประเภท"
                    onChange={onStatusFilterChange}
                    allowClear
                    filterOption={(input, option) => option.label.indexOf(input) >= 0 }
                  >
                    <Option value={undefined} label="ทั้งหมด" > ทั้งหมด </Option>
                    <Option value="A" label="ใช้งานได้"> ใช้งานได้ </Option>
                    <Option value="C" label="ระงับการใช้งาน" > ระงับการใช้งาน </Option>
                    <Option value="P" label="รออนุมัติ"> รออนุมัติ </Option>
                  </Select>
                </div>
              </div>
            </div>
          </div>
          <div className="row button-position no-gutters">
            <div className="my-1 px-1 col-5">
              <Button
                type="primary"
                className="btn-lottery-line"
                onClick={showQuotaHistories}
              >
                สรุปยอด
              </Button>
              <AgentQuotaSummary visible={isQuotaHistoriesVisible} onCancel={hideQuotaHistories}/>
            </div>
            <div className="my-1 px-1 col-7">
              <button
                type="button"
                className="btn btn-lottery btn-block d-flex align-items-center justify-content-center"
                onClick={openDrawer}
              >
                <i className="feather-icon icon-user-plus mr-2"></i>
                <span>เพิ่มตัวแทน</span>
              </button>
              <AgentDetailDrawer formName="agent_minor" />
            </div>
          </div>
        </nav>
      </div>
    );
  }
);
