import React, { useRef, useState, useEffect } from 'react'
import { Input, Modal, Spin } from 'antd'
import { clazz } from '../../util/common-util'
import { formatNumber } from '../../util/math'

const AdjustType = {
  Increase: 'Increase',
  Decrease: 'Decrease',
}

const AgentAdjustQuota = ({
  loading, visible, onClose, onSave, agent, quotaLimit,
}) => {

  const inputRef = useRef()
  const [adjustType, setAdjustType] = useState(AdjustType.Increase);
  const [adjustAmount, setAdjustAmount] = useState();

  useEffect(() => {
    if (!visible) {
      setAdjustType(AdjustType.Increase)
      setAdjustAmount(undefined)
    }
  }, [visible])

  useEffect(() => {
    setAdjustAmount(undefined)
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [adjustType])

  function onAdjustTypeChange(type) {
    return () => setAdjustType(type)
  }

  function onAdjustAmountChange(event) {
    if (event.target.validity.valid) {
      setAdjustAmount(event.target.value)
    }
  }

  function calResult() {
    return adjustType === AdjustType.Increase
      ? formatNumber(+(agent?.quota ?? 0) + +(adjustAmount ?? 0))
      : formatNumber(+(agent?.quota ?? 0) - +(adjustAmount ?? 0))
  }

  function onAdjustQuotaSave() {
    const changeApplyType = adjustType === AdjustType.Increase
      ? +adjustAmount : -adjustAmount;
    onSave({
      username: agent.username,
      change: changeApplyType,
      price: agent.price,
    })
  }

  return (
    <Modal title="โควตา" visible={visible} onCancel={onClose} centered
      wrapClassName="adjust-quota-modal lottery-modal content-no-padding" footer={null}>
      <Spin spinning={loading}>
        <div>
          <div className="adjust-type-bar" id="myTab" role="tablist">
            <div className={clazz('adjust-type adjust-type-increase right', { cond: adjustType === AdjustType.Increase, clz: 'active-bt-bar' })}
              onClick={onAdjustTypeChange(AdjustType.Increase)}>
              <div className="icon-container">
                <i className="feather-icon icon-plus"></i>
              </div>
              <span>เพิ่มโควตา</span>
            </div>
            <div className={clazz('adjust-type adjust-type-decrease left', { cond: adjustType === AdjustType.Decrease, clz: 'active-bt-bar' })}
              onClick={onAdjustTypeChange(AdjustType.Decrease)}>
              <div className="icon-container">
                <i className="feather-icon icon-minus"></i>
              </div>
              <span>
                ลดโควตา
              </span>
            </div>
          </div>
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
              <div className="row justify-content-center">
                <div className="col-8 my-4 mx-5">
                  <div className="border boder-lottery text-center py-3" style={{ borderRadius: '18px' }}>
                    <div className="text-gray">
                      โควตาล่าสุด
                    </div>
                    <h3 className="text-main mb-0">
                      {formatNumber(agent?.quota)}
                    </h3>
                  </div>
                  <div className="form-group py-3">
                    <label className="text-gray">ระบุจำนวนโควตาที่ต้องการ</label>
                    <Input ref={inputRef} value={adjustAmount} className="form-control border-lottery input-focus" placeholder="ระบุจำนวน"
                      type="number" min="0" max={adjustType === AdjustType.Increase ? quotaLimit : agent?.quota}
                      onChange={onAdjustAmountChange}
                    />
                    {adjustType === AdjustType.Increase ? (
                      <div className="text-remind mt-1">จำนวนโควตาที่ต้องการเพิ่ม ( เพิ่มได้ไม่เกิน<span className="px-1">{formatNumber(quotaLimit)}</span>)</div>
                    ) : (
                      <div className="text-remind mt-1">จำนวนโควตาที่ต้องการลด ( ลดได้ไม่เกิน<span className="px-1">{formatNumber(agent?.quota)}</span>)</div>
                    )}
                  </div>
                  <div className="text-center bg-gray py-3" style={{ borderRadius: '30px' }}>
                    <div className="text-gray">
                      โควตาทั้งหมด
                    </div>
                    <h3 className="text-main mb-0">
                      {calResult()}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer d-block justify-content-center">
          <div className="row">
            <div className="col-6  pr-1">
              <button type="button" className="btn btn-lottery-gray btn-block my-2 " onClick={onClose}>
                ยกเลิก
              </button>
            </div>
            <div className="col-6 pl-1">
              <button type="button" className="btn btn btn-lottery btn-block my-2 "
                onClick={onAdjustQuotaSave}>
                บันทึก
              </button>
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  )
}

export default React.memo(AgentAdjustQuota)