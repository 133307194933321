import createSagaMiddleware from "redux-saga";
import {applyMiddleware, compose, createStore} from 'redux'
import {routerMiddleware} from 'connected-react-router'
import rootSaga from "../sagas/index";
import createRootReducer from '../reducers'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { SIGNOUT_USER } from "../../const/ActionTypes";

const createBrowserHistory = require('history').createBrowserHistory;
export const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware, routeMiddleware];

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth']
}

const appReducer = createRootReducer(history);
const rootReducer = (state, action) => {
  if (action.type === SIGNOUT_USER) {
    state = undefined
  }
  return appReducer(state, action)
}

export let store;
export let persistor;

export default function configureStore(preloadedState) {
  return new Promise((resolve, reject) => {
    try {
      store = createStore(
        persistReducer(persistConfig, rootReducer),
        preloadedState,
        compose(
          applyMiddleware(
            routerMiddleware(history), // for dispatching history actions
            ...middlewares
          ),
          (window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()) || compose
        ),
      );

      persistor = persistStore(store, null, () => resolve());
      sagaMiddleware.run(rootSaga);

    } catch (e) {
      reject(e);
    }
  });
}


// export default function configureStore(preloadedState) {
//   const store = createStore(
//     rootReducer,
//     preloadedState,
//     compose(
//       applyMiddleware(
//         routerMiddleware(history), // for dispatching history actions
//         ...middlewares
//       ),
//       (window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()) || compose
//     ),
//   );

//   sagaMiddleware.run(rootSaga);
//   return store;
// }
