import React from 'react';
import { Input } from 'antd';
import { escapeRegExp, isEmpty } from './common-util'
import { SearchOutlined, CloseOutlined } from '@ant-design/icons'

const DEFAULT_SORT_CONFIG = {
    numeric: true, 
    sensitivity: 'accent', // non-sensitive
    ignorePunctuation: true,
}

// const SORT_TYPE = {
//     TEXT: 'text',
//     NUMBER: 'number',
// };

// generate form filter panel
function generateFilterPanel(props) {
    const { placeholder = 'ค้นหา...' } = props;
    return function({ setSelectedKeys, selectedKeys, confirm, clearFilters }) {
        return (
            <div className="custom-filter-dropdown">
                <Input className="rounded-pill"
                    ref={ele => this.searchInput = ele}
                    placeholder={placeholder}
                    value={selectedKeys ? selectedKeys[0] : ''}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : []) }
                    onPressEnter={this.handleSearch(selectedKeys, confirm)}
                    suffix={<CloseOutlined style={{ color: "#838383"}} theme="filled" 
                    onClick={this.handleReset(clearFilters)}/>}
                />
            </div>
        );
   }
}

function generateFilterPanelHook(props) {
    const { 
        placeholder = 'ค้นหา...',
        handleSearch, handleReset, inputRef,
    } = props;
    return function({ setSelectedKeys, selectedKeys, confirm, clearFilters }) {
        return (
            <div className="custom-filter-dropdown">
                <Input className="rounded-pill"
                    ref={inputRef} style={{ color: "#838383", paddingLeft: 20 }}
                    placeholder={placeholder}
                    value={selectedKeys ? selectedKeys[0] : ''}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : []) }
                    onPressEnter={handleSearch(selectedKeys, confirm)}
                    suffix={<CloseOutlined style={{ color: "#dc3545"}} theme="filled" 
                    onClick={handleReset(clearFilters)}/>}
                />
            </div>
        );
   }
}

// highlight text that's filtered
function displayTextFilter(filteredInfo = '', filterField, props = {}) {
    const searchText = (typeof filteredInfo === 'string') 
        ? filteredInfo
        : (filteredInfo[filterField]||[])[0];
    return function(text) {
        if ( searchText && !isEmpty(text) ) {
            const searchTexts = searchText.toLowerCase().split(' ')
                .map(t => t.replace(/_+/g, ' ')); // replace '_' in meaning space bar
            const searchEscapedTexts = searchTexts.map(t => escapeRegExp(t));
            const regTexts = searchEscapedTexts.map(t => `(${t})`).join('|');
            return (
                <span {...props}>
                {
                    String(text).split(new RegExp(regTexts, 'i')).filter(v => !!v)
                        .map((fragment, i) => (
                            searchTexts.includes(fragment.toLowerCase())
                            ? <span key={i} className="filtered-highlight">{fragment}</span> 
                            : fragment ))
                }
                </span>
            )
        } else {
            return ( <span {...props}>{text}</span> )
        }
    }
}

// focus intput element when filter panel visible 
function onFilterDropdownVisibleChange(visible) {
    setTimeout(() => {
        if (visible && this.searchInput) {
            this.searchInput.focus();
        }
    }, 100);
}

function onFilterDropdownVisibleChangeHook(props) {
    return (visible) => {
        setTimeout(() => {
            if (visible && props.inputRef.current) {
                props.inputRef.current.focus();
            }
        }, 100); // 100 for prevent windows scroll up [dont know why]
    }
}

// icon
function filterIcon(filtered) {
    return <SearchOutlined style={{ color: filtered ? '#108ee9' : 'rgb(123, 121, 121)' }} />
}

function replaceSpace(value) {
    if ( value === undefined || value === null ) {
        return value;
    }
    return String(value).replace(/\s+/g, '_');
}

// ** if return true then it will render this row
// ** field can be string or function or string array
function onFilterText(field) {
    return function(searchText, record) {
        let value;
        switch ( typeof field ) {
            case 'string': value = replaceSpace(record[field]); break;
            case 'function': value = replaceSpace(field(record)); break;
            case 'object': value = field.map(f => replaceSpace(record[f])||'').join(' ').trim(); break;
            default: value = record[field]; break;
        }
        if ( isEmpty(value) || isEmpty(searchText) ) {
            return false;
        }

        value = typeof field !== 'string' ? String(value) : value;
        return searchText.split(' ').some(text => {
            return value.toLowerCase().includes(text.replace(/_+/g, '_').toLowerCase()); // replace '_' in meaning space bar
        });
    }
}

// get individual filter field
function filteredValue(filteredInfo, field) {
    if ( !filteredInfo ) return null;
    return filteredInfo[field] || null;
}

// normal sort text
function sortor(field) {
    return function(a, b) {
        const aValue = typeof field === 'function' ? field(a) : a[field];
        const bValue = typeof field === 'function' ? field(b) : b[field];

        const dataA = !isEmpty(aValue) ? aValue : undefined;
        const dataB = !isEmpty(bValue) ? bValue : undefined;

        if ( !isEmpty(dataA) && isEmpty(dataB) ) {
            return -1;
        } else if ( isEmpty(dataA) && !isEmpty(dataB) ) {
            return 1;
        } else {
            return String(dataA).localeCompare(String(dataB), 'th', DEFAULT_SORT_CONFIG);
        }
    }
}

// for Hook
const searchFilterUtil = (filteredInfo, dataIndex, stuff = {}) => {
    return {
        filterIcon,
        filteredValue: filteredValue(filteredInfo, dataIndex),
        onFilter: onFilterText(stuff.targetSearch || dataIndex),
        filterDropdown: generateFilterPanelHook(stuff),
        onFilterDropdownVisibleChange: onFilterDropdownVisibleChangeHook(stuff),
    }
}

const indexed = paginationInfo => (_, __, index) => {
    const offset = paginationInfo ? ( paginationInfo.current -1 ) * paginationInfo.pageSize : 0;
    return offset + ( index + 1 );
}

const filterTextAll = ({
    text = '', columns, data,
}) => {
    if (text) {
        text = text.toLowerCase();
        const fields = columns.map(c => ({ field: c.dataIndex ?? c.key, convert: c.convert }))
        const filtered = data.filter(item =>
            fields.some(({ field, convert }) => {
                const value = (convert ? `${convert(item[field])??''}` : `${item[field] ?? ''}`);
                return value.toLowerCase().includes(text);
            }))
        return filtered;
    } else {
        return data;
    }
}

export {
    indexed,
    displayTextFilter,
    generateFilterPanel,
    onFilterText,
    onFilterDropdownVisibleChange,
    filterIcon,
    filteredValue,
    sortor,
    searchFilterUtil,
    filterTextAll,
}
