import React, { useRef } from 'react'
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { isMobileDevice } from '../../../util/common-util';
import { LotteryAction } from '../../../appRedux/actions/Lottery'
import { motion, AnimateSharedLayout } from 'framer-motion';
import ProfileShortcut from './ProfileShortcut';
import { springTransition } from '../../../const/animation';
import { ReadOutlined, ShopOutlined, DropboxOutlined, ApartmentOutlined, DollarCircleOutlined } from '@ant-design/icons'
import { CommonSelector } from 'appRedux/selector';
import { OrderAction } from 'appRedux/actions/Order';

const LotteryHeader = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const mobileToggleMenuRef = useRef();
  const showMenuBar = useSelector(CommonSelector.showMenuBar);

  function isMatch(currLocation) {
    return location.pathname === currLocation ? 'active' : '';
  }

  function changeMenu(menuPath, additionalCallback) {
    return () => {
      if (location.pathname !== menuPath) {
        if (isMobileDevice()) {
          mobileToggleMenuRef.current.click();
        }

        if (additionalCallback)
          additionalCallback();

        history.push(menuPath);
      }
    }
  }

  function renderUnderline(isSelect) {
    return isSelect && (
      <motion.div layoutId="underline" className="active-underline"
        initial={false} transition={springTransition}></motion.div>
    )
  }

  return (
    <>
      <div className="head-navbar-lottery">
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between">
            <span className="navbar-brand-logo">
              <img src={require("assets/images/logo_red-tiger.png")} alt="logo" />
            </span>
            <ProfileShortcut />
          </div>
        </div>
      </div>
      { showMenuBar && (
        <nav className="navbar navbar-expand-lg navbar-light navbar-lottery mb-3"
          style={{ position: 'sticky', top: 0, zIndex: 100 }}
        >
          <div className="container-fluid">

            {/* Toggle Mobile Menu */}
            <button ref={mobileToggleMenuRef}
              className="navbar-toggler my-2" type="button"
              data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
              aria-expanded="false" aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <AnimateSharedLayout transition={{ duration: .5 }}>
                <ul className="navbar-nav ml-auto">

                  <li className={`nav-item ${isMatch('/main')}`}
                    onClick={changeMenu('/main')}>
                    <motion.span className="nav-link" animate>
                      <i className="feather-icon icon-home mr-1"></i>หน้าหลัก
                    </motion.span>
                    {renderUnderline(isMatch('/main'))}
                  </li>

                  <li className={`nav-item ${isMatch('/shop')}`}
                    onClick={changeMenu('/shop', () => dispatch(LotteryAction.initLottery()))}>
                    <motion.span className="nav-link" animate>
                      <ShopOutlined style={{ display: 'inline-block', verticalAlign: 'middle', paddingBottom: '2px' }} /> ตั้งค่าหน้าร้าน
                    </motion.span>
                    {renderUnderline(isMatch('/shop'))}
                  </li>

                  <li className={`nav-item ${isMatch('/lottery-search')}`}
                    onClick={changeMenu('/lottery-search', () => dispatch(LotteryAction.initLottery()))}>
                    <motion.span className="nav-link" animate>
                      <i className="feather-icon icon-search mr-1"></i>ค้นหาลอตเตอรี่
                    </motion.span>
                    {renderUnderline(isMatch('/lottery-search'))}
                  </li>

                  <li className={`nav-item ${isMatch('/booking-ordering')}`}
                    onClick={changeMenu('/booking-ordering', () => dispatch(OrderAction.initPage()))}>
                    <motion.span className="nav-link" animate>
                      <ReadOutlined style={{ display: 'inline-block', verticalAlign: 'middle', paddingBottom: '2px' }} /> การจองและสั่งซื้อ
                    </motion.span>
                    {renderUnderline(isMatch('/booking-ordering'))}
                  </li>

                  <li className={`nav-item ${isMatch('/agent')}`}
                    onClick={changeMenu('/agent')} >
                    <motion.span className="nav-link" animate >
                    <ApartmentOutlined style={{ display: 'inline-block', verticalAlign: 'middle', paddingBottom: '2px' }}/> ข้อมูลตัวแทน
                    </motion.span>
                    {renderUnderline(isMatch('/agent'))}
                  </li>

                  <li className={`nav-item ${isMatch('/customer')}`}
                    onClick={changeMenu('/customer')}>
                    <motion.span className="nav-link" animate>
                      <i className="feather-icon icon-users mr-1"></i>ข้อมูลลูกค้า
                    </motion.span>
                    {renderUnderline(isMatch('/customer'))}
                  </li>

                  <li className={`nav-item dropdown ${isMatch('/check-reward')} ${isMatch('/fee')}`}>
                   <span className="nav-link dropdown-toggle" id="navbarDropdownMenuLink2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                   <i className="feather-icon icon-award mr-1"></i>ตรวจผลรางวัล
                   </span>
                   <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink2">
                      <span className="dropdown-item"  onClick={changeMenu('/check-reward')}> <i className="feather-icon icon-award mr-1"></i>รายการถูกรางวัล</span>
                      <span className="dropdown-item"  onClick={changeMenu('/fee')}><DollarCircleOutlined style={{ display: "inline-block", verticalAlign: "middle", paddingBottom: "2px" }} /> ค่าธรรมเนียม</span>
                    </div>
                    {renderUnderline(isMatch('/check-reward'))}{renderUnderline(isMatch('/fee'))}
                    </li>

                  <li className={`nav-item dropdown ${isMatch('/minor')}`}>
                    <span className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i className="feather-icon icon-list mr-1"></i>จัดการรายย่อย
                    </span>
                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                      <span className="dropdown-item" onClick={changeMenu('/minor')}>การจองและการสั่งซื้อ</span>
                      {/* <span className="dropdown-item">การจัดเก็บลอตเตอรี่ที่ถูกรางวัล</span> */}
                    </div>
                    {renderUnderline(isMatch('/minor'))}
                  </li>

                  <li className={`nav-item dropdown ${isMatch('/lottery-box')} ${isMatch('/booking-ordering-lottery-box')}`}>
                    <span className="nav-link dropdown-toggle" id="navbarDropdownMenuLink2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <DropboxOutlined  style={{ display: 'inline-block', verticalAlign: 'middle', paddingBottom: '2px' }} /> ลอตเตอรี่กล่อง
                    </span>
                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink2">
                      <span className="dropdown-item" onClick={changeMenu('/lottery-box')}>
                        ค้นหาลอตเตอรี่กล่อง
                      </span>
                      <span className="dropdown-item" onClick={changeMenu('/booking-ordering-lottery-box', () => dispatch(OrderAction.initPage()))}>
                        การจองและการสั่งซื้อ
                      </span>
                    </div>

                  </li> 

                </ul>
              </AnimateSharedLayout>
            </div>
            {/* Toggle Mobile Menu */}
          </div>
        </nav>
      )}
    </>
  )
}

export default React.memo(LotteryHeader);
