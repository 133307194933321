import {all} from "redux-saga/effects";
import authSagas from "./Auth";
import notesSagas from "./Notes";
import agentSaga from "./Agent";
import lotterySaga from "./Lottery";
import customerSaga from "./Customer";
import orderSaga from "./Order";
import dashBoardSaga from "./DashBoard";
import shopSaga from "./Shop";
import commonSaga from "./Common";
import notificationSaga from "./์Notification";

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    notesSagas(),
    ...agentSaga,
    ...lotterySaga,
    ...customerSaga,
    ...orderSaga,
    ...dashBoardSaga,
    ...shopSaga,
    ...commonSaga,
    ...notificationSaga,
  ]);
}
