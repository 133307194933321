import { OrderActionType, FAILURE, REQUEST, SUCCESS } from "../../const/ActionTypes";
import Immu from '../../util/immu-util'

const orderDetailInitial = {
  customer: {},
  items: [],
}

const initSearchFilter = { status: 'reserve', type: 'all', quotaType: 'N' };
const initPaginable = { current: 1, pageSize: 10 };
const orderListPageInitial = {
  filterTextAll: '',
  searchFilter: { ...initSearchFilter },
  paginable: { ...initPaginable },
  tableData: {},
}

const INIT_STATE = {
  loading: false,
  items: [],
  totalItem: 0,
  orderDetail: { ...orderDetailInitial },
  orderList: { ...orderListPageInitial },
};

export default (state = INIT_STATE, { type, payload, error }) => {
  switch (type) {
    case OrderActionType.GET_ORDER_LIST[REQUEST]:
    case OrderActionType.GET_ORDER_LOTTERY_BOX_LIST[REQUEST]:
      return { ...state, loading: true, items: [] }

    case OrderActionType.GET_ORDER_DETAIL[REQUEST]:
    case OrderActionType.GET_ORDER_LOTTERY_BOX_DETAIL[REQUEST]:
      return { ...state, loading: true, orderDetail: orderDetailInitial }


    case OrderActionType.GET_ORDER_LIST[SUCCESS]:
    case OrderActionType.GET_ORDER_LOTTERY_BOX_LIST[SUCCESS]:
    case OrderActionType.GET_ORDER_DETAIL[SUCCESS]:
    case OrderActionType.GET_ORDER_LOTTERY_BOX_DETAIL[SUCCESS]:
      return { ...state, loading: false, ...(payload||{}) }


    case OrderActionType.GET_ORDER_LIST[FAILURE]:
    case OrderActionType.GET_ORDER_LOTTERY_BOX_LIST[FAILURE]:
    case OrderActionType.GET_ORDER_DETAIL[FAILURE]:
    case OrderActionType.GET_ORDER_LOTTERY_BOX_DETAIL[FAILURE]:
      return { ...state, loading: false, error }

    case OrderActionType.CHANGE_OWNER[REQUEST]:
      return { ...state, loading: true }
    case OrderActionType.CHANGE_OWNER[SUCCESS]:
      return Immu.merge(state, {
        path: 'orderDetail.customer', state: (payload||{})
      })
    case OrderActionType.CHANGE_OWNER[FAILURE]:
      return { ...state, loading: false, error: (payload||{}) }

    case OrderActionType.INIT_ORDER_LIST:
      return { ...state, orderList: { ...orderListPageInitial } }

    case OrderActionType.SEARCH_FILTER_ORDER_LIST:
      return Immu.merge(state,
        { path: 'orderList.searchFilter', state: payload, },  
        (!payload.lotteryDate || payload.lotteryDate !== state.orderList.searchFilter.lotteryDate)
          && { path: 'orderList.paginable', state: initPaginable, },  
      )

    case OrderActionType.TABLE_DATA_ORDER_LIST:
      return Immu.merge(state, {
        path: 'orderList.tableData', state: payload,
      })

    case OrderActionType.PAGING_ORDER_LIST:
      return Immu.merge(state, {
        path: 'orderList.paginable', state: (payload||{}),
      })

    case OrderActionType.FILTER_TEXT_ORDER_LIST:
      return { ...state, orderList: { ...state.orderList, filterTextAll: payload } }

    default:
      return state;
  }
}
