import ReactGA from 'react-ga';

export const getGoogleAnalyticsId = () => process.env.REACT_APP_GOOGLE_ANALYTICS_ID

export const isGoogleAnalyticsEnabled = () => {
  return process.env.REACT_APP_ENABLE_GOOGLE_ANALYTICS === 'true'
}

export const initializeGoogleAnalytics = () => {
  if (isGoogleAnalyticsEnabled()) {
    const gaOptions = {
      debug: true,
    }
    ReactGA.initialize(getGoogleAnalyticsId(), gaOptions)
  }
}

export const trackingPageView = () => {
  if (isGoogleAnalyticsEnabled()) {
    const url = window.location.pathname + window.location.search;
    console.log('tracking:', url)
    ReactGA.pageview(url);
  }
}