import React, { useState, useMemo, useCallback } from 'react'
import { Dropdown, Menu } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import { userSignOut } from '../../../appRedux/actions/Auth';
import AgentDetailDrawer from '../../../routes/Agent/AgentDetailDrawer';
import { AgentAction } from '../../../appRedux/actions/Agent';
import { AgentContext } from '../../../routes/Agent';
import { AgentSelector } from 'appRedux/selector';
import NotificationBell from './NotificationBell';

const ProfileShortcut = () => {

  const dispatch = useDispatch();
  const [agentDetailVisible, setAgentDetailVisible] = useState(false)
  const detail = useSelector(AgentSelector.agentDetail);

  const openAgentDetailDrawer = useCallback(() => {
    setAgentDetailVisible(true)
  }, [])

  const closeAgentDetailDrawer = useCallback(() => {
    setAgentDetailVisible(false)
  }, [])

  const onSaveAgent = useCallback(async (formData) => {
    const success = await new Promise(resolver => {
      dispatch(AgentAction.saveNewAgent(formData, resolver))
    });

    if (success) {
      closeAgentDetailDrawer();
    }
  }, [dispatch, closeAgentDetailDrawer])

  const menu = useMemo(() => {
    const signOut = () => {
      dispatch(userSignOut())
    }

    return (
      <Menu>
        <Menu.Item>
          <span onClick={openAgentDetailDrawer} className="dropdown-item">แก้ไขข้อมูลส่วนตัว</span>
        </Menu.Item>
        <Menu.Item>
          <span onClick={signOut} className="dropdown-item">ออกจากระบบ</span>
        </Menu.Item>
      </Menu>
    )
  }, [dispatch, openAgentDetailDrawer])

  const { avatar, firstName, lastName, level } = detail;

  return (
    <AgentContext.Provider value={{
      agent: detail,
      drawerVisible: agentDetailVisible,
      openDrawer: openAgentDetailDrawer,
      closeDrawer: closeAgentDetailDrawer,
      onSaveAgent: onSaveAgent,
    }}>
      <NotificationBell className="ml-auto"/>
      <Dropdown overlay={menu} placement="bottomCenter">
        <div className="d-flex align-items-center pointer">
          <div className="avatar-cover bg-user-icon mr-3">
            {!avatar ? <i className="feather-icon icon-user "></i> : <img className="avatar-img" src={avatar} alt="avatar" />}
          </div>
          <div className="d-none-edit">
            <div className="user-name">{`${firstName ?? ''} ${lastName ?? ''}`}</div>
            <div className="user-status">{level}</div>
          </div>
        </div>
      </Dropdown>
      <AgentDetailDrawer editSelfProfile={true} formName="profile"/>
    </AgentContext.Provider>
  )
}

export default React.memo(ProfileShortcut)