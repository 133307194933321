import { NotificationActionType } from 'const/ActionTypes';
import ImmuUtil from 'util/immu-util'

const initialState = {
  notifications: [],
}


export default (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case NotificationActionType.SET_NOTIFICATION:
      return { ...state, notifications: payload }
    
    case NotificationActionType.PUSH_NOTIFICATION:
      return ImmuUtil.push(state, { path: 'notifications', state: payload })

    case NotificationActionType.READ_NOTIFICATION:
      return ImmuUtil.arrayRemove(state,
        { path: 'notifications', state: { index: (notifications) => notifications.findIndex(noti => noti.id === payload) } })

    default: return state;
  }
}
