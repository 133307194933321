import React, { useState, useCallback, createContext } from "react";
import { Pagination } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AgentAction } from "../../appRedux/actions/Agent";
import AgentList from "./AgentList";
import { applyPaging } from "../../util/common-util";
import AgentQuota from "./AgentQuota";
import { motion } from 'framer-motion';
import AgentHeaderBar from "./AgentHeaderBar";
import useMount from "../../components/hook/useMount";
import { trackingPageView } from "../../util/google-analytics";
import { AgentSelector } from "appRedux/selector";

const containerVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 }
}

export const AgentContext = createContext();

const Agent = () => {

  const dispatch = useDispatch();
  const { loading, agents, detail } = useSelector(AgentSelector.agent);
  const [paginable, setPaginable] = useState({ current: 1, pageSize: 10 })
  const [editingAgent, setEditingAgent] = useState()
  const [filterInfo, setFilterInfo] = useState({})
  const [agentDetailVisible, setAgentDetailVisible] = useState(false)

  useMount(() => {
    dispatch(AgentAction.getAgents());
    trackingPageView()
  })

  const openAgentDetailDrawer = useCallback(() => {
    setAgentDetailVisible(true)
  }, [])

  const closeAgentDetailDrawer = useCallback(() => {
    setAgentDetailVisible(false)
    setEditingAgent(undefined)
  }, [])

  const onEditAgent = useCallback(agent => {
    setEditingAgent(agent)
    openAgentDetailDrawer()
  }, [openAgentDetailDrawer])

  const onSaveAgent = useCallback(async (formData, isEdit) => {
    const success = await new Promise(resolver => {
      if (!isEdit) {
        dispatch(AgentAction.addNewAgent(formData, resolver))
      } else {
        dispatch(AgentAction.saveNewAgent(formData, resolver))
      }
    });

    if (success) {
      closeAgentDetailDrawer();
    }
  }, [dispatch, closeAgentDetailDrawer])

  function onPaginationChange(current, pageSize) {
    setPaginable(p => ({ ...p, current, pageSize }))
  }

  function filterAll(dataSource) {
    const fields = [
      'username', 'firstName', 'aliasName', 'phone', 
      'level', 'quota', 'price', 'statusDesc', 'updateDateDisplay',
    ];
    if(dataSource){
    const filtered = dataSource
      .filter(ds =>
        fields.some(field => {
          return `${ds[field] ?? ''}`.toLowerCase().includes((filterInfo.textFilter ?? '').toLowerCase());
        }))
      .filter(ds => !filterInfo.statusFilter || ds.status === filterInfo.statusFilter)
      .filter(ds => !filterInfo.quotaTypeFilter || ds.quotaType === filterInfo.quotaTypeFilter)
      .filter(ds => !filterInfo.levelFilter || ds.level === filterInfo.levelFilter)
    return filtered;
      }else{
        return [];
      }
  }

  const filteredAgentList = filterAll(agents);

  return (
    <AgentContext.Provider value={{
      agent: editingAgent,
      drawerVisible: agentDetailVisible,
      openDrawer: openAgentDetailDrawer,
      closeDrawer: closeAgentDetailDrawer,
      onSaveAgent: onSaveAgent,
    }}>
      <motion.div className="py-3" variants={containerVariants} initial="hidden" animate="visible">
        <div className="AGENT-PAGE">
          <div className="container-fluid">
            <div className="head-lootery">
              <h3 className="mb-3">ข้อมูลตัวแทน</h3>
            </div>
            <div className="body-lootery">
              <div className="row">
                <div className="col-12">
                <AgentQuota quotaInfo={detail} />
                </div>
                {/* <div className="col-12 col-lg-6">
                <AgentQuota quotaInfo={detail} />
                </div> */}
              </div>
              {/* <AgentQuota quotaInfo={detail} /> */}
              <div className="row">
                <div className="col-12 my-2">
                  <div className="card-body-lottery">
                    <AgentHeaderBar filterInfo={filterInfo} onFilterChange={setFilterInfo} />
                    <div className="row mt-4">
                      <div className="table-responsive">
                        <AgentList dataSource={applyPaging(filteredAgentList, paginable)}
                          loading={loading} onEditAgent={onEditAgent} quotaInfo={detail}
                          textFilter={filterInfo.textFilter} />
                      </div>
                    </div>
                    <ul className="pagination pagination-lottery mb-0 justify-content-end mt-2">
                      <Pagination responsive showSizeChanger
                        onShowSizeChange={onPaginationChange} onChange={onPaginationChange}
                        total={filteredAgentList.length} {...paginable} />
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </AgentContext.Provider>
  );
};

export default React.memo(Agent);
