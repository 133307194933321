import ErrorBoundary from "components/ErrorBoundary";
import Loader from "components/Loader";
import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";

const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <ErrorBoundary>
      <Switch>
        <Route path={`${match.url}main`} component={LazyLoad(lazy(() => import('./DashBoard')))} />
        <Route path={`${match.url}shop`} component={LazyLoad(lazy(() => import('./Shop')))} />
        <Route path={`${match.url}lottery-search`} component={LazyLoad(lazy(() => import('./LotterySearch')))} />
        <Route path={`${match.url}agent`} component={LazyLoad(lazy(() => import('./Agent')))} />
        <Route path={`${match.url}order`} component={LazyLoad(lazy(() => import('./Order')))} />
        <Route path={`${match.url}orderBox`} component={LazyLoad(lazy(() => import('./OrderBox')))} />
        <Route path={`${match.url}booking-ordering`} component={LazyLoad(lazy(() => import('./BookingAndOrdering/Provider/Lottery')))} />
        <Route path={`${match.url}booking-ordering-lottery-box`} component={LazyLoad(lazy(() => import('./BookingAndOrdering/Provider/LotteryBox')))} />
        <Route path={`${match.url}order-detail`} component={LazyLoad(lazy(() => import('./OrderDetail/Provider/OrderLotteryDetail')))} />
        <Route path={`${match.url}order-lottery-box-detail`} component={LazyLoad(lazy(() => import('./OrderDetail/Provider/OrderLotteryBoxDetail')))} />
        <Route path={`${match.url}customer`} component={LazyLoad(lazy(() => import('./Customer')))} />
        <Route path={`${match.url}check-reward`} component={LazyLoad(lazy(() => import('./LotteryWinners')))} />
        <Route path={`${match.url}minor`} component={LazyLoad(lazy(() => import('./Minor')))} />
        <Route path={`${match.url}lottery-box`} component={LazyLoad(lazy(() => import('./LotteryBox')))} />
        <Route path={`${match.url}fee`} component={LazyLoad(lazy(() => import('./FeeManage')))} />

        <Route path={`${match.url}loader`} component={LazyLoad(lazy(() => import('./Loader')))} />
      </Switch>
    </ErrorBoundary>
    <Loader />
  </div>
);

export default React.memo(App);

function LazyLoad(LazyComponent) {
  return props => (
    <Suspense fallback={<div>Loading...</div>}>
      <LazyComponent {...props} />
    </Suspense>
  );
}