import React, { memo, useEffect, useRef } from 'react'
import { clazz } from 'util/common-util';
import { EventSourcePolyfill } from 'event-source-polyfill/src/eventsource.min.js'
import { useDispatch, useSelector } from 'react-redux';
import { AuthSelector, NotificationSelector } from 'appRedux/selector';
import { NotificationAction } from 'appRedux/actions/Notification';
import { Badge } from 'antd';
import { useHistory, useLocation } from 'react-router';
import Sound from 'assets/sound/noti.mp3'
import { DollarCircleOutlined } from '@ant-design/icons'
// import { CommonAPI } from 'appRedux/services/Common';

const SSE_TYPE = 'agent-order-notification';
const notificationSound = (() => {
  const sound = new Audio(Sound)
  if (sound) {
    sound.volume = 1;
    return sound;
  }
  return null;
})();;


const NotificationBell = ({ className }) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const token = useSelector(AuthSelector.token)
  const notifications = useSelector(NotificationSelector.notifications)
  const isOrderDetailPage = location.pathname.split('/').includes('order-detail')
  const notificationsTmp = useRef([]);

  useEffect(() => {
    const validLength = notifications.length > 0;
    const validNewContentComing = validLength && (() => {
      if (notifications.length < notificationsTmp.current.length) {
        return false;
      }
      if (notificationsTmp.current.length !== notifications.length) {
        return true;
      } else {
        const oldIds = notificationsTmp.current;
        const newIds = notifications.map(n => n.id);
        const allNewInOld = newIds.every(newId => oldIds.includes(newId))
        const allOldInNew = oldIds.every(oldId => newIds.includes(oldId))
        const allNotificationsIsOld = allNewInOld && allOldInNew;
        return !allNotificationsIsOld;
      }
    })();

    try {
      if (validNewContentComing) {
        if (notificationSound) {
          notificationSound.play();
        }
        if (window.navigator) {
          window.navigator.vibrate(200);
        }
      }
    } catch (error) { }

    notificationsTmp.current = notifications.map(n => n.id);
  }, [notifications])

  useEffect(() => {
    let sse = null;

    function onmessage(event) {
      try {
        if ('END-OF-STREAM' === event.data) {
          sse.close();
        } else {
          const { notifications } = JSON.parse(event.data);
          if (notifications.length > 0) {
            dispatch(NotificationAction.setNotifications(notifications));
            // dispatch(NotificationAction.pushNotifications(payload.notifications));
          }
        }
      } catch (error) {
        console.error(error)
      }
    }

    // async function checkTokenAlive() {
    //   await CommonAPI.checkTokenAlive()
    // }

    if (token) {
      const domain = process.env.REACT_APP_NOTIFICATION_ENDPOINT;
      if (domain) {
        const baseUrl = process.env.REACT_APP_BASE_API;
        const url = domain + baseUrl + '/agent/order/notification'
        sse = new EventSourcePolyfill(url, {
          headers: { 'Authorization': token },
          heartbeatTimeout: 5 * 60 * 1000,
        });

        sse.addEventListener(SSE_TYPE, onmessage);
        // sse.onerror = error => {
        //   console.error('sse error')
        //   checkTokenAlive()
        //   // dispatch(refreshToken())
        // }
        // let connected;
        // const connectionPromise = new Promise(resolve => connected = resolve);
        // sse.onopen = sse.onopen = function () {
        //   connected();
        //   console.log(`connection is opened. ${sse.readyState}`)
        // }

        // connectionPromise.then(() => {
        //   setTimeout(() => {
        //     dispatch(NotificationAction.initNotifications())
        //   }, 2000)
        // })
      }
    }

    return () => {
      if (sse) {
        sse.removeEventListener(SSE_TYPE, onmessage)
        sse.close();
      }
    }
  }, [token, dispatch])

  const onRead = (notification) => () => {
    dispatch(NotificationAction.readNotification(notification.id))
    const redirectMethod = isOrderDetailPage ? history.replace : history.push;
    redirectMethod('/order-detail', { txnId: notification.txnId })
  }

  return (
    <div className={clazz('notification-bell nav-item dropdown mr-4', className)}>
      <Badge count={notifications.length} overflowCount={99}>
        <span
          className="nav-link pe-2 nav-item dropdown"
          href="#"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
        >
          <div className="icon-noti">
            <i className="feather-icon icon-bell"></i>
          </div>
        </span>
        <ul
          className="dropdown-notification dropdown-menu dropdown-menu-right dropdown-menu-lottery dropdown-menu-noti pt-0"
          aria-labelledby="navbarDropdown"
        >
          <div className="order-title text-center border-bottom px-2 py-3">
            <span className="text-main">รายการสั่งซื้อ</span>
          </div>
          {notifications.map(notification => (
            <li key={notification.id} onClick={onRead(notification)}>
              <span className="dropdown-item  dropdown-item-noti justify-content-start d-flex">
                {notification.type === 'order' ? (
                  <div className="bg-noti-icon order">
                    <i className="feather-icon icon-shopping-cart"></i>
                  </div>
                ) : (
                  <div className="bg-noti-icon slip">
                    <DollarCircleOutlined style={{ color: 'white', fontSize: 20 }} />
                  </div>
                )}
                <div className="list-noti">
                  <div className="title-noti">{notification.type === 'order' ? 'สั่งซื้อหน้าร้าน' : 'สลิปอัพโหลด'}</div>
                  <div className="description-noti">{notification.message}</div>
                </div>
              </span>
            </li>
          ))}
        </ul>
      </Badge>
    </div>
  )
}

export default memo(NotificationBell);