import React from "react";
import { formatNumber } from "../../util/math";
import { motion } from "framer-motion";

const AgentQuota = ({ quotaInfo }) => {

  const isQuotaTypeCredit = quotaInfo.quotaType === 'C'

  return (
    <div className="row">
      <div className="col-12 col-lg">
        {/* Quota */}
        <motion.div
          className="row no-gutters d-flex align-content-stretch my-3"
          initial="hidden"
          animate="visible"
        >
          <motion.div
            className="col-lg-12 d-flex"
            variants={quotaLabelVariants}
          >
            <h5 className="bg-gradient-main card-body-lottery d-flex justify-content-center align-items-center w-100 h-100 py-1 py-sm-3 py-lg-3">
              <i className="feather-icon icon-pie-chart mr-2"></i> โควต้า
            </h5>
          </motion.div>
          <motion.div
            className="col-lg-12"
            variants={quotaValueVariants}
          >
            <div className="card-body-lottery">
              <div className="d-flex">
                <div className="quota-data col ">
                  <div className="label-text">จอง</div>
                  <div className="label-number">
                    {formatNumber(quotaInfo.reserve ?? 0)}
                  </div>
                </div>
                <div className="quota-data col ">
                  <div className="label-text">ซื้อ</div>
                  <div className="label-number">
                    {formatNumber(quotaInfo.buy ?? 0)}
                  </div>
                </div>
                <div className="quota-data col ">
                  <div className="label-text">เครดิตที่ถูกใช้ไป</div>
                  <div className="label-number">
                    {formatNumber(quotaInfo.creditOrder ?? 0)}
                  </div>
                </div>
                <div className="quota-data col ">
                  <div className="label-text">คงเหลือ</div>
                  <div className="label-number">
                    {formatNumber(quotaInfo.quota ?? 0)}
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </div>
      {/* Credit */}
      <div className="col-12 col-lg">
        <motion.div
          className="row no-gutters d-flex align-content-stretch my-3"
          initial="hidden"
          animate="visible"
        >
          <motion.div className="col-lg-12 d-flex" variants={quotaLabelVariants} >
            <h5 className="bg-gradient-main card-body-lottery d-flex justify-content-center align-items-center w-100 h-100 py-1 py-sm-3 py-lg-3">
              <i className="feather-icon icon-credit-card mr-2"></i>
            เครดิต {(isQuotaTypeCredit && quotaInfo.credit === null) && '(ไม่จำกัด)'}
            </h5>
          </motion.div>
          <motion.div className="col-lg-12" variants={quotaValueVariants} >
            <div className="card-body-lottery">
              <div className="d-flex">
                <div className="quota-data col ">
                  <div className="label-text">จอง</div>
                  <div className="label-number">
                    {!isQuotaTypeCredit ? '-' : formatNumber(quotaInfo.creditReserve ?? 0)}
                  </div>
                </div>
                <div className="quota-data col ">
                  <div className="label-text">ซื้อ</div>
                  <div className="label-number">
                    {!isQuotaTypeCredit ? '-' : formatNumber(quotaInfo.creditBuy ?? 0)}
                  </div>
                </div>
                <div className="quota-data col ">
                  <div className="label-text">คงเหลือ</div>
                  <div className="label-number">
                    {!isQuotaTypeCredit
                      ? '-'
                      : quotaInfo.credit === null
                        ? 'ไม่จำกัด'
                        : formatNumber(quotaInfo.credit ?? 0)}
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default React.memo(AgentQuota);

const quotaLabelVariants = {
  hidden: { x: -100 },
  visible: { x: 0 },
};

const quotaValueVariants = {
  hidden: { x: 100 },
  visible: { x: 0 },
};
