import {
  FETCH_ERROR, FETCH_START, FETCH_SUCCESS, HIDE_MESSAGE, 
  SHOW_MESSAGE, SHOW_MENU_BAR, HIDE_MENU_BAR, CommonActionType,
  FAILURE, SUCCESS, TOGGLE_COLLAPSED_NAV, WINDOW_WIDTH,
} from 'const/ActionTypes'

const INIT_STATE = {
  error: "",
  loadingCentral: false,
  loading: false,
  message: '',
  navCollapsed: true,
  width: window.innerWidth,
  pathname: '/',
  showMenuBar: true,
  drawDateList: [],
  bankList: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE': {
      return {
        ...state,
        pathname: action.payload.location.pathname,
        navCollapsed: false
      }
    }
    case WINDOW_WIDTH:
      return {
        ...state,
        width: action.width,
      };
    case TOGGLE_COLLAPSED_NAV: {
      return {
        ...state,
        navCollapsed: action.navCollapsed
      }
    }
    case FETCH_START: {
      return {...state, error: '', message: '', loading: true};
    }
    case FETCH_SUCCESS: {
      return {...state, error: '', message: '', loading: false};
    }
    case SHOW_MESSAGE: {
      return {...state, error: '', message: action.payload, loading: false};
    }
    case FETCH_ERROR: {
      return {...state, loading: false, error: action.payload, message: ''};
    }
    case HIDE_MESSAGE: {
      return {...state, loading: false, error: '', message: ''};
    }
    case SHOW_MENU_BAR: {
      return {...state, showMenuBar: true };
    }
    case HIDE_MENU_BAR: {
      return {...state, showMenuBar: false };
    }
    case 'SERVICE_WORKER_INITIALIZE': {
      return {...state, swInit: true };
    }
    case 'SERVICE_WORKER_UPDATED': {
      return {...state, swUpdate: true, register: action.payload };
    }
    case 'SERVICE_WORKER_IGNORED': {
      return {...state, swIgnore: true };
    }
    case CommonActionType.GET_DRAW_DATE[SUCCESS]: {
      return {...state, drawDateList: action.payload };
    }
    case CommonActionType.GET_DRAW_DATE[FAILURE]: {
      return {...state, error: action.payload };
    }
    case CommonActionType.GET_BANK_LIST[SUCCESS]: {
      return {...state, bankList: action.payload };
    }
    case CommonActionType.GET_BANK_LIST[FAILURE]: {
      return {...state, error: action.payload };
    }
    case CommonActionType.LOADER_CENTRAL: {
      return {...state, loadingCentral: action.payload };
    }
    default:
      return state;
  }
}
