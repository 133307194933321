import { LotteryClientAPI } from './LotteryCreator';

export const AgentAPI = {
  getAgents: params => LotteryClientAPI.get('/agent', { params }),
  getQuotaHistory: params => LotteryClientAPI.get('/agent/quota/history', { params }),
  addNewAgent: payload => LotteryClientAPI.post('/agent/register', payload),
  saveNewAgent: payload => LotteryClientAPI.post('/agent/update', payload),
  changeQuota: payload => LotteryClientAPI.post('/agent/quota', payload),
  changeCredit: payload => LotteryClientAPI.post('/agent/credit', payload),
  getMinorAgent: () => LotteryClientAPI.get('/minor/option'),
  getMinorAgentOrderInfoList: payload => LotteryClientAPI.post('/minor/orders', payload),
  getQuotaSummary: params => LotteryClientAPI.get('/agent/quota/summary/history', { params }),
  // ?date=16-03-2021&from=16-03-2021&to=18-03-2021
}