export const formatNumber = (number, precision) => {
    if ( (!number && number !== 0) || Number.isNaN(+number) ) 
        return number;
    
    if ( precision !== undefined ) {
        number = (+number).toFixed(precision);
    } else {
        number = number + '';
    }

    const [integer, decimal] = number.split('.');
    const formatedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const formatedDecimal = !decimal ? '' :  `.${decimal}`;

    return `${formatedInteger}${formatedDecimal}`;
}
