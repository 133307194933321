import React, { memo } from 'react'
import { Spin } from "antd";
import { useSelector } from 'react-redux';
import { CommonSelector } from 'appRedux/selector';

const Loader = () => {
  const loading = useSelector(CommonSelector.loadingCentral)
  if (loading) {
    return (
      <div className="LOADER">
        <Spin />
      </div>
    )
  } else {
    return null;
  }
}

export default memo(Loader);
