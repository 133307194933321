import { call, put, takeLatest } from "redux-saga/effects";
import { DashBoardAction } from '../actions/DashBoard'
import { DashBoardActionType, REQUEST } from '../../const/ActionTypes'
import { DashBoardAPI } from '../services/DashBoard'
import dayjs from "dayjs";

function* getNews() {
    try {
        const response = yield call(DashBoardAPI.getNews);
        response.data.result.forEach(news => {
            news.createDateDisplay = dayjs(news.createDate).format('DD/MM/YYYY');
        })
        yield put(DashBoardAction.getNewsSuccess(response.data));
    } catch (error) {
        yield put(DashBoardAction.getNewsFailure(error));
    }
}

export default [
    takeLatest(DashBoardActionType.GET_NEWS[REQUEST], getNews),
]