import {
  FETCH_ERROR, FETCH_START, FETCH_SUCCESS, HIDE_MESSAGE, 
  SHOW_MESSAGE, SHOW_MENU_BAR, HIDE_MENU_BAR, CommonActionType,
  REQUEST, SUCCESS, FAILURE,
} from "../../const/ActionTypes";

export const fetchStart = () => {
  return {
    type: FETCH_START
  }
};

export const fetchSuccess = () => {
  return {
    type: FETCH_SUCCESS
  }
};

export const fetchError = (error) => {
  return {
    type: FETCH_ERROR,
    payload: error
  }
};

export const showMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  }
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE
  }
};

export const showMenuBar = () => {
  return {
    type: SHOW_MENU_BAR
  }
};

export const hideMenuBar = () => {
  return {
    type: HIDE_MENU_BAR
  }
};

export const getDrawDate = () => ({ type: CommonActionType.GET_DRAW_DATE[REQUEST] });
export const getDrawDateSuccess = payload => ({ type: CommonActionType.GET_DRAW_DATE[SUCCESS], payload });
export const getDrawDateFailure = payload => ({ type: CommonActionType.GET_DRAW_DATE[FAILURE], payload });

export const getBankList = () => ({ type: CommonActionType.GET_BANK_LIST[REQUEST] });
export const getBankListSuccess = payload => ({ type: CommonActionType.GET_BANK_LIST[SUCCESS], payload });
export const getBankListFailure = payload => ({ type: CommonActionType.GET_BANK_LIST[FAILURE], payload });

export const setLoading = payload => ({ type: CommonActionType.LOADER_CENTRAL, payload })