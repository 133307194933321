import { CommonSelector } from 'appRedux/selector'
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

const useDrawDate = (onInit) => {
  const init = useRef(false);
  const drawDateList = useSelector(CommonSelector.drawDateList);

  useEffect(() => {
    if (
      onInit && !init.current 
      && drawDateList && drawDateList.length > 0
    ) {
      init.current = true;
      onInit(drawDateList[0].value);
    }
  }, [drawDateList, onInit])

  return drawDateList;
}

export default useDrawDate;