import React, { useRef, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import { Plus, Edit2 } from 'react-feather'
import { CreditCardOutlined, HistoryOutlined, ShopTwoTone } from '@ant-design/icons'
import { motion } from 'framer-motion';
import IconLabel from '../../components/misc/IconLabel'
import useControlVisible from '../../components/hook/useControlVisible'
import { clazz, formatPhoneNumber } from '../../util/common-util';
import AgentTransactionModal from './AgentTransactionModal';
import { getDrawDate } from '../../appRedux/actions/Common'
import AgentAdjustQuota from './AgentAdjustQuota'
import { AgentAction } from '../../appRedux/actions/Agent'
import { displayTextFilter } from '../../util/table-util'
import { Tooltip } from 'antd';
import { getAgentStatusBadgeColorClass } from '../../const'
import AdjustCreditModal from './AdjustCreditModal'
import { formatNumber } from 'util/math'

const AgentList = React.memo(({
  dataSource, onEditAgent, quotaInfo, textFilter,
}) => {

  const dispatch = useDispatch()
  const blocking = useRef(false)
  const [loading, setLoading] = useState(false)
  const alreadyGetDrawDateOnce = useRef()
  const [currentAgentAction, setCurrentAgentAction] = useState()
  const [tsModalVisible, setTsModalVisible] = useState(false)
  const [adjustQuotaModalVisible, setAdjustQuotaModalVisible] = useState(false)
  const [{ visible: adjustCredit, agent: agentCredit }, , [showAdjustCredit, closeAdjustCredit]] = useControlVisible({})

  const closeTsModal = useCallback(() => {
    setTsModalVisible(false)
    setCurrentAgentAction(undefined)
  }, [])

  const closeAdjustQuotaModal = useCallback(() => {
    setAdjustQuotaModalVisible(false)
    setCurrentAgentAction(undefined)
  }, [])

  const onAdjustQuota = useCallback(async (data) => {
    if (!blocking.current) {
      try {
        blocking.current = true
        setLoading(true)
        const lotteryResultDate = dayjs(quotaInfo.currentLotteryDate).format('DD-MM-YYYY')
        const payload = {
          lotteryResultDate,
          ...data,
        }
        const success = await new Promise(resolver => dispatch(AgentAction.changeQuota(payload, resolver)))
        if (success) {
          closeAdjustQuotaModal()
        }
      } catch (_) {
      } finally {
        blocking.current = false
        setLoading(false)
      }
    }
  }, [closeAdjustQuotaModal, quotaInfo, dispatch])

  const onAdjustCredit = useCallback(async (payload) => {
    const success = await new Promise(resolver => dispatch(AgentAction.changeCredit(payload, resolver)))
    if (success) {
      closeAdjustCredit()
    }
  }, [closeAdjustCredit, dispatch])

  function openTsModal(agent) {
    return () => {
      setTsModalVisible(true)
      setCurrentAgentAction(agent)
      if (!alreadyGetDrawDateOnce.current) {
        alreadyGetDrawDateOnce.current = true
        dispatch(getDrawDate())
      }
    }
  }

  function openAdjustQuotaModal(agent) {
    return () => {
      setAdjustQuotaModalVisible(true)
      setCurrentAgentAction(agent)
    }
  }

  function onEdit(agent) {
    return () => onEditAgent(agent)
  }

  return (
    <>
      <motion.table className="table table-hover table-lottery" variants={agentsVariants} initial="hidden" animate="visible">
        <thead>
          <tr>
            <th scope="col">ชื่อเข้าสู่ระบบ</th>
            <th scope="col">ชื่อ</th>
            <th scope="col">เบอร์โทร</th>
            <th scope="col">ประเภทผู้ใช้งาน</th>
            <th scope="col">โควต้า</th>
            <th scope="col">เครดิต</th>
            <th scope="col">ยอดขายเครดิต</th>
            <th scope="col">ราคาต่อใบ</th>
            <th scope="col">สถานะ</th>
            <th scope="col">ปรับปรุงล่าสุด</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {dataSource.map(agent => {
            return (
              <tr key={agent.username}>
                <td>
                  <Tooltip title={`${agent.brandName}`}>
                    <ShopTwoTone style={{ display: 'inline-block', verticalAlign: 'middle', paddingBottom: '2px', cursor: 'pointer' }} />  </Tooltip>{displayTextFilter(textFilter)(agent.username)}
                </td>
                <td>{displayTextFilter(textFilter)(agent.fullname)}</td>
                <td>{displayTextFilter(textFilter)(formatPhoneNumber(agent.phone))}</td>
                <td className="text-center">{displayTextFilter(textFilter)(agent.role)}</td>
                <td className="text-right">
                  {displayTextFilter(textFilter)(formatNumber(agent.quota) || '0')}
                </td>
                <td className="text-right">
                  {agent.quotaType === 'C' && (
                    agent.credit !== null
                      ? displayTextFilter(textFilter)(formatNumber(agent.credit))
                      : 'ไม่จำกัด'
                  )}
                </td>
                <td className="text-right">
                  {agent.quotaType === 'C' && displayTextFilter(textFilter)(formatNumber(agent.creditOrder) || '0')}
                </td>
                <td className="text-right">{displayTextFilter(textFilter)(agent.price)}</td>
                <td><span className={getAgentStatusBadgeColorClass(agent.status)}>
                  {displayTextFilter(textFilter)(agent.statusDesc)}
                </span></td>
                <td>{displayTextFilter(textFilter)(agent.updateDateDisplay)}</td>
                <td>
                  <div className="agent-actions">
                    <motion.div className="button-pill rounded-pill font-small" whileHover={buttonWhileHover}
                      onClick={openAdjustQuotaModal(agent)}>
                      <IconLabel
                        icon={<CircleIcon icon={<Plus size="1.3em" />} />}
                        label="โควตา"
                        align="center" />
                    </motion.div>
                    {agent.quotaType === 'C' && (
                      <motion.div className="button-pill rounded-pill font-small" whileHover={buttonWhileHover}
                        onClick={() => showAdjustCredit({ agent })}>
                        <IconLabel
                          icon={<CircleIcon icon={<CreditCardOutlined size="1.3em" />} />}
                          label="เครดิต"
                          align="center" />
                      </motion.div>
                    )}
                    <motion.div className="button-pill rounded-pill font-small" whileHover={buttonWhileHover}
                      onClick={openTsModal(agent)}>
                      <IconLabel
                        icon={<CircleIcon icon={<HistoryOutlined />} className="rotate-x-180" />}
                        label="ธุรกรรม"
                        align="center" />
                    </motion.div>
                    <motion.div className="button-pill rounded-pill font-small" whileHover={buttonWhileHover}
                      onClick={onEdit(agent)}>
                      <IconLabel
                        icon={<CircleIcon icon={<Edit2 size={10} />} />}
                        label="แก้ไข"
                        align="center" />
                    </motion.div>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </motion.table>

      <AgentTransactionModal
        visible={tsModalVisible}
        onClose={closeTsModal}
        agentName={currentAgentAction?.username} />
      <AgentAdjustQuota
        loading={loading}
        visible={adjustQuotaModalVisible}
        onClose={closeAdjustQuotaModal}
        agent={currentAgentAction}
        quotaLimit={quotaInfo?.quota}
        onSave={onAdjustQuota} />
      <AdjustCreditModal
        visible={adjustCredit}
        onSave={onAdjustCredit}
        onClose={closeAdjustCredit}
        agent={agentCredit}
        quotaLimit={quotaInfo?.quota}
        lotteryResultDate={quotaInfo.currentLotteryDate} />
    </>
  )
});

export default AgentList;

const agentsVariants = {
  hidden: { y: 100 },
  visible: { y: 0 },
}

const buttonWhileHover = {
  scale: 1.1,
}

function CircleIcon({ icon, className = '' }) {
  return (
    <div className={clazz('circle-icon', className)}>
      {icon}
    </div>
  )
}