export const _TOKEN_ = 'ltkrt';
export const FRONT_URL = process.env.REACT_APP_FRONT_URL;
export const ORDER_LOTTERY_FRONT_VIEW = `${FRONT_URL}/order?txnId=`
export const ORDER_LOTTERY_BOX_FRONT_VIEW = `${FRONT_URL}/order-lottery-box?txnId=`
export const LOTTERY_FRONT_VIEW = `${FRONT_URL}/search-lottery?lottery=`
export const SHOP_FRONT_VIEW = `${FRONT_URL}/shop?psid=`
export const EXTERNAL_URL_PARAM = `&openExternalBrowser=1`

export const DefaultPaginable = { 
    current: 1, 
    pageSize: 10, 
    showSizeChanger: true, 
    pageSizeOptions: ['10', '20', '50', '100'],
}

export const UserAgentStatusLabel = {
  A: 'ใช้งานได้',
  C: 'ระงับการใช้งาน',
  P: 'รออนุมัติ'
}

export const UserAgentType = {
  agent: 'ตัวแทน',
  staff: 'ผู้ช่วย',
  customer: 'ลูกค้า',
  system: 'ระบบ',
}

export const OrderStatus = {
  purchase: 'สั่งซื้อ',
  reserve: 'จอง',
  expire: 'หมดเวลา',
  cancel: 'ยกเลิก',
}

export const getStatusBadgeColorClass = status => {
  switch (status) {
    case 'purchase': return 'badge badge-pill badge-success badge-success-lottery';
    case 'reserve': return 'badge badge-pill badge-warning-lottery';
    case 'expire':return 'badge badge-pill badge-timeup-lottery';
    case 'cancel': return 'badge badge-pill badge-danger-lottery';
    default: return 'badge badge-pill';
  }
}

export const getAgentStatusBadgeColorClass = status => {
  switch (status) {
    case 'A': return 'badge badge-pill badge-success badge-success-lottery font-small';
    case 'C': return 'badge badge-pill badge-warning-lottery font-small';
    case 'P':return 'badge badge-pill badge-success badge-success-lottery font-small';
    default: return 'badge badge-pill';
  }
}

export const ReceivePayment = {
  None: 'ไม่ระบุ',
  Bank: 'ธนาคาร',
  Prompt: 'พร้อมเพย์',
}