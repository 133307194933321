import { call, put, takeLatest } from "redux-saga/effects";
import { ShopAction } from '../actions/Shop'
import { ShopActionType, REQUEST } from '../../const/ActionTypes'
import { ShopAPI } from '../services/Shop'
import Notification from '../../util/notification'
// import { handleResponse } from '../../util/common-util'
// import { UserShopStatusLabel } from '../../const'
// import dayjs from "dayjs";


function* getShop() {
  try {
    const response = yield call(ShopAPI.getShop);
    // response.data.result.forEach(shop => {
    // if(agent.aliasName){
    //     agent.fullname = `${agent.firstName} (${agent.aliasName})`;
    // }else{
    //     agent.fullname = `${agent.firstName} ${agent.lastName}`;
    // }
    // agent.statusDesc = UserShopStatusLabel[agent.status];
    // agent.updateDateDisplay = dayjs(agent.updateDate).format('DD/MM/YYYY');
    // })
    yield put(ShopAction.getShopSuccess({ shopData: response.data.result }));
  } catch (error) {
    yield put(ShopAction.getShopFailure(error));
  }
}

function* saveShop({ payload }) {
  try {
    const response = yield call(ShopAPI.updateShop, payload);
    const status = response.data.status
    if (status === "0") {
      yield put(ShopAction.saveShopSuccess(response.data.result));
      Notification.alertInfo("อัพเดทข้อมูลสำเร็จ")
      yield put(ShopAction.getShops())
    } else {
      const errorMessage = response.data.message;
      yield put(ShopAction.saveShopFailure(errorMessage));
      Notification.alertError(errorMessage)
    }
  } catch (error) {
    yield put(ShopAction.saveShopFailure(error));
  }
}

function* changeLineAccountStatus({ payload }) {
  try {
    yield call(ShopAPI.changeLineAccountStatus, payload);
    Notification.alertInfo((payload.send ? 'เปิด' : 'ปิด') + "การใช้งานแอคเคาท์เรียบร้อย")
    yield put(ShopAction.changeLineAccountSuccess(payload))
  } catch (error) {
    yield put(ShopAction.changeLineAccountFailure(error));
  }
}

function* deleteLineAccount({ payload }) {
  try {
    yield call(ShopAPI.deleteLineAccount, payload);
    Notification.alertInfo("ยกเลิกการผูกแอคเคาท์สำเร็จ")
    yield put(ShopAction.deleteLineAccountSuccess(payload))
  } catch (error) {
    yield put(ShopAction.deleteLineAccountFailure(error));
  }
}

export default [
  takeLatest(ShopActionType.GET_SHOP[REQUEST], getShop),
  takeLatest(ShopActionType.SAVE_SHOP[REQUEST], saveShop),
  takeLatest(ShopActionType.SHOP_LINE_CHANGE_STATUS[REQUEST], changeLineAccountStatus),
  takeLatest(ShopActionType.SHOP_LINE_DELETE[REQUEST], deleteLineAccount),
]