import React, { useCallback, useState } from "react";
import { Button, Input, Form } from "antd";
import { useDispatch } from "react-redux";
import { UserOutlined, LockOutlined, EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import imgLogin from "../assets/images/img-login.jpg"
import { userSignIn, } from "appRedux/actions/Auth";
import { motion } from 'framer-motion'
import ForgotPassword from "./ForgotPassword";

const SignIn = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm()
  const [showForgotPassword, isShowForgotPassword] = useState(false)
  const onFinishFailed = errorInfo => console.log('Failed:', errorInfo);
  const onFinish = values => login(values);
  const login = payload => {
    dispatch(userSignIn({
      username: payload.username.toUpperCase(),
      password: payload.password,
    }));
  }

  const cancelForgotPassword = useCallback(() => {
    isShowForgotPassword(false)
  }, [])

  const resetPasswordSuccess = useCallback((username, password) => {
    form.setFieldsValue({ username, password })
    isShowForgotPassword(false)
  }, [form])

  return (
    <motion.div className="bg-white page-login"
      initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: .4, duration: .4, ease: 'easeIn' } }}
    >
      <div className="row h-100 no-gutters">
        <div className="col-lg-6 d-none-edit">
          <div className="img-login" style={{ backgroundImage: `url('${imgLogin}')` }}></div>
        </div>
        <div className="col-lg-6">
          {!showForgotPassword && (
            <div className="row justify-content-center row-credential">
              <div className="col-xl-8 col-lg-9" style={{ padding: 0 }}>
                <div className="content-login-agent">
                  <h6>ยินดีต้อนรับเข้าสู่ระบบ</h6>
                  <h4 className="text-main">เสือแดงล็อตเตอรี่ ออนไลน์</h4>

                  <Form
                    form={form}
                    name="authen"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    className="gx-signin-form gx-form-row0"
                  >
                    <label className="py-1">ชื่อผู้ใช้งาน</label>
                    <Form.Item rules={[{ required: true, message: 'กรุณาระบุชื่อผู้ใช้งาน' }]} name="username">
                      <Input className="form-login text-uppercase" prefix={<UserOutlined className="site-form-item-icon" />} placeholder="ชื่อผู้ใช้งาน" />
                    </Form.Item>
                    <label className="py-1">รหัสผ่าน</label>
                    <Form.Item rules={[
                      { required: true, message: 'กรุณาระบุรหัสผ่าน' },
                      { min: 8, message: 'รหัสผ่านอย่างน้อย 8 หลัก' },
                    ]} name="password">
                      <Input.Password className="form-login" prefix={<LockOutlined className="site-form-item-icon" />} placeholder="รหัสผ่าน"
                        iconRender={passVisible => (passVisible ? <EyeTwoTone twoToneColor="#dc3545" /> : <EyeInvisibleOutlined />)} maxLength={20} />
                    </Form.Item>
                    <span className="mb-2 forgot-password" onClick={() => isShowForgotPassword(true)}>
                      ลืมรหัสผ่าน
                    </span>
                    <div className="row">
                      <div className="col-12 col-lg-6">
                        <Button type="primary" htmlType="submit" className="login-form-button btn-lottery btn-lg px-5 w-100">
                          เข้าสู่ระบบ
                        </Button>
                      </div>
                    </div>
                  </Form>
                  <div className="slot-qr-code">
                    <h6 className="text-main">ยินดีต้อนรับสู่ครอบครัวเสือแดง</h6>
                    <a href="https://line.me/ti/g2/AhAiwvbD4ZQ02EQ7o3_gOQ?utm_source=invitation&utm_medium=link_copy&utm_campaign=default"
                      target="_Blank" rel="noopener noreferrer">
                      <motion.img alt="logo red tiger" src={require("assets/images/qr-redtiger.jpg")}
                        initial={{ scale: 1.2 }} animate={{ scale: 1, transition: { delay: .4, duration: 0.4, ease: 'easeIn' } }}
                      /></a>
                    <small className="mt-2 p-2 d-block">ตัวแทน สามารถเข้ากลุ่ม ครอบครัว เสือแดง ล็อตเตอรี่ โดยการคลิกที่ภาพ QR code หรือ แสกน QR code ใช้ USER และ เบอโทรศัพท์ ในการยืนยันตัวตนค่ะ</small>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Part Forgot pass */}
          {showForgotPassword && (
            <ForgotPassword success={resetPasswordSuccess} cancel={cancelForgotPassword}/>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default SignIn;
