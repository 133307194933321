import { takeLatest, call, put, select } from 'redux-saga/effects'
import { CommonActionType, REQUEST } from '../../const/ActionTypes'
import { CommonAPI } from '../services/Common'
import { getDrawDateSuccess, getDrawDateFailure, getBankListSuccess, getBankListFailure } from '../actions/Common'
import { convertToFullThaiFormat } from '../../util/common-util'
import { CommonSelector } from 'appRedux/selector'

const getDrawDate = function*() {
    try {
        let drawDateList = yield select(CommonSelector.drawDateList);
        if ( !drawDateList || drawDateList.length === 0 ) {
          const response = yield call(CommonAPI.getDrawDate);
          yield put(getDrawDateSuccess(response.data.result.map(date => ({ label: convertToFullThaiFormat(date), value: date }))));
        }
    } catch (error) {
        yield put(getDrawDateFailure(error));
    }
}

const getBankList = function*() {
    try {
        let bankList = yield select(CommonSelector.bankList);
        if ( !bankList || bankList.length === 0 ) {
            const response = yield call(CommonAPI.getBankList);
            const bankList = response.data.result.map(b => ({ ...b, label: b.thaiName, value: `${b.bankPrefix}_${b.thaiName}`}))
            yield put(getBankListSuccess(bankList));
        }
    } catch (error) {
        yield put(getBankListFailure(error));
    }
}

export default [
    takeLatest(CommonActionType.GET_DRAW_DATE[REQUEST], getDrawDate),
    takeLatest(CommonActionType.GET_BANK_LIST[REQUEST], getBankList),
];