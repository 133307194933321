import { CustomerActionType, FAILURE, REQUEST, SUCCESS } from "../../const/ActionTypes";

const INIT_STATE = {
    loading: false,
    result: [],
};

export default (state = INIT_STATE, { type, payload = {} }) => {
    switch (type) {
        case CustomerActionType.GET_CUSTOMER_LIST[REQUEST]: {
            return { ...state, loading: true }
        }

        case CustomerActionType.GET_CUSTOMER_LIST[SUCCESS]: {
            return { ...state, loading: false, ...payload }
        }

        case CustomerActionType.GET_CUSTOMER_LIST[FAILURE]: {
            return { ...state, loading: false, error: payload }
        }

        default:
            return state;
    }
}
