import React, { memo, useState } from 'react'
import { Button, Input, Form, Space, Statistic } from "antd";
import dayjs from 'dayjs';
import styled from 'styled-components'
import Pin from 'components/Pin';
import { AuthAPI } from 'appRedux/services/Auth';
import Notification from 'util/notification'
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons'

const ForgotPassword = ({ success, cancel }) => {

  const [form] = Form.useForm()
  const [otpRef, setOtpRef] = useState('')
  const [isSendingOTP, setSendingOTP] = useState(false)
  const [isSentOTP, setSentOTP] = useState(false)
  const [otpCountdown, setOtpCountdown] = useState()
  const [canResendOTP, setCanResendOTP] = useState(false)
  const [isConfirmDisabled, setConfirmDisabled] = useState(true)

  const sendOTP = async () => {
    try {
      setSendingOTP(true)
      const params = { username: form.getFieldValue('username').toUpperCase() }
      const response = await AuthAPI.sendOTP(params)
      setOtpRef(response.data.result.otpRef)
      setSentOTP(true)
      setConfirmDisabled(false)
      setCanResendOTP(false)
      setOtpCountdown(dayjs().add(5, 'minutes'))
    } catch (error) {
      Notification.alertError(error.message)
    } finally {
      setSendingOTP(false)
    }
  }

  const approveOTP = async () => {
    try {
      const otpValues = form.getFieldValue('otpValue').split('')
      const payload = {
        otpRef,
        username: form.getFieldValue('username').toUpperCase(),
        password: form.getFieldValue('password'),
        digit1: otpValues[0],
        digit2: otpValues[1],
        digit3: otpValues[2],
        digit4: otpValues[3],
      }
      await AuthAPI.approveOTP(payload)
      Notification.alertInfo('เปลี่ยนรหัสผ่านเรียบร้อย')
      success(payload.username, payload.password)
    } catch (error) {
      Notification.alertError(error.message)
    }
  }

  return (
    // <div className="row justify-content-center" style={{ minHeight: '100vh' }}>
    <div className="row justify-content-center">
      <div className="col-xl-8 col-lg-10">
        <Form form={form} layout="vertical">
          <div className="content-forgot-agent">
            <h3>ลืมรหัสผ่าน</h3>
            <label className="py-1 mb-0">ชื่อผู้ใช้งาน</label>
            <Form.Item name="username">
              <Input className="text-uppercase" placeholder="กรุณาระบุชื่อผู้ใช้งาน" disabled={isSentOTP} />
            </Form.Item>
            <div className="row">
              {isSentOTP && (
                <div className="col-sm-7 col-12 py-2">
                  <Form.Item name="otpValue" className="mb-2">
                    <Pin pinInput={4} autoFocus />
                  </Form.Item>
                </div>
              )}
              {/* <div className="col-sm-auto col-12 py-2 px-1"> */}
              <div className="col-sm-auto col-12 py-2">
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => (
                    <Button onClick={sendOTP}
                      type="primary" className="login-form-button btn-lottery-line btn-lg"
                      disabled={
                        !getFieldValue('username')?.length
                        || (isSentOTP && !canResendOTP)
                        || isSendingOTP
                      }>
                      {isSendingOTP
                        ? 'กำลังส่ง OTP...'
                        : (
                          isSentOTP ? (
                            <Counter className="d-flex justify-content-center">
                              <span>กดรับ OTP</span>
                              {!canResendOTP && (
                                <span className="ml-2 d-flex">
                                  <Statistic.Countdown
                                    format="s"
                                    value={dayjs().add(1, 'minutes')}
                                    onFinish={() => setCanResendOTP(true)} />
                                  <span className="ml-2 text-main">วินาที</span>
                                </span>
                              )}
                            </Counter>
                          )
                            : 'ยืนยันและรับ OTP'
                        )}
                    </Button>
                  )}
                </Form.Item>
              </div>
              {!isSentOTP && (
                <div className="col-sm-4 col-12 py-2">
                  <Button onClick={cancel}
                    type="primary" className="login-form-button btn-lottery-line btn-lg btn-block">
                    ยกเลิก
                  </Button>
                </div>
              )}
            </div>
            <div>
              {isSentOTP && (
                <>
                  <div className="my-2">
                    <div className="mx-2">รหัสอ้างอิง : {otpRef}</div>
                    <Counter className="d-flex">
                      {/* <SyncOutlined className="site-form-item-icon mt-1" /> */}
                      <span className="mx-2">รหัสจะหมดอายุภายใน</span>
                      <Statistic.Countdown
                        format="mm:ss"
                        value={otpCountdown}
                        onFinish={() => setConfirmDisabled(true)} />
                      <span className="ml-2 text-main">นาที</span>
                    </Counter>
                  </div>

                  <div className="my-4">
                    {/* Password */}
                    <Form.Item label="รหัสผ่าน" rules={[
                      { required: true, message: 'กรุณาระบุรหัสผ่าน' },
                      { min: 8, message: 'รหัสผ่านอย่างน้อย 8 หลัก' },
                      { max: 20, message: 'รหัสผ่านมากสุด 20 หลัก' },
                    ]} name="password">
                      <Input.Password autoComplete="new-password" placeholder="รหัสผ่านใหม่"
                        iconRender={passVisible => (passVisible ? <EyeTwoTone twoToneColor="#dc3545" /> : <EyeInvisibleOutlined />)}
                        minLength={8} maxLength={20} />
                    </Form.Item>

                    {/* Confirm Password */}
                    <Form.Item name="confirmPassword" label="ยืนยันรหัสผ่าน" dependencies={['password']}
                      rules={[
                        { required: true, message: 'กรุณายืนยันรหัสผ่าน' },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('รหัสผ่านไม่ตรงกัน!'));
                          },
                        })]}
                    >
                      <Input.Password autoComplete="new-password" placeholder="ยืนยันรหัสผ่าน"
                        iconRender={passVisible =>
                          (passVisible ? <EyeTwoTone twoToneColor="#dc3545" /> : <EyeInvisibleOutlined />)}
                        maxLength={20} />
                    </Form.Item>
                  </div>
                </>
              )}

              <Space>
                {isSentOTP && (
                  <>
                    <Form.Item noStyle shouldUpdate>
                      {({ getFieldValue, getFieldsError, isFieldsTouched }) => (
                        <Button type="primary" className="login-form-button btn-lottery btn-lg px-5"
                          onClick={approveOTP}
                          disabled={
                            isConfirmDisabled
                            || getFieldValue('otpValue')?.replace(/\*/g, '').length !== 4
                            || getFieldsError().filter(({ errors }) => errors.length).length > 0
                            || !isFieldsTouched(true)
                          }>
                          ยันยัน
                        </Button>
                      )}
                    </Form.Item>
                    <Button onClick={cancel}
                      type="primary" className="login-form-button btn-lottery-line btn-lg px-5">
                      ยกเลิก
                    </Button>
                  </>
                )}
              </Space>
            </div>
          </div>
        </Form>
      </div>
    </div >
  )
}

export default memo(ForgotPassword);

const Counter = styled.div`
  .ant-statistic-content{
    font-size: 14px;
    .ant-statistic-content-value {
      color: #d33031;
    }
  }
`