import dayjs from 'dayjs'
import { message } from 'antd'

const thaiMonths = [
  'มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน',
  'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'];

export function isMobileDevice() {
  let isMobile = false;
  if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(navigator.userAgent.substr(0, 4))) {
    isMobile = true;
  }
  return isMobile;
}

export function applyPaging(dataSource, paginable) {
  const { current, pageSize } = paginable;
  var offset = (current - 1) * pageSize;
  return dataSource.slice(offset, offset + pageSize);
}

export function toPaginable(paginable) {
  return {
    page: paginable.current,
    perPage: paginable.pageSize
  }
}

export const clazz = (...clazzes) => {
  return clazzes.reduce((r, o) => {
    return typeof o === 'string' || typeof o === 'undefined'
      ? `${r} ${o || ''}`
      : o.cond
        ? `${r} ${o.clz}`
        : o.else ? `${r} ${o.else}` : r;
  }, '').trim();
}

export const convertToFullThaiFormat = (date, format = 'D M YYYY') => {
  if (!date) return date;
  return dayjs(date)
    .add(543, 'year')
    .format(format)
    .replace(/\s+(\d+)\s+/, (_, month) => ` ${thaiMonths[month - 1]} `);

}

export const convertToFullEngFormat = (date, format = 'D MMMM YYYY') => {
  if (!date) return date;
  return dayjs(date).format(format);

}

export function escapeRegExp(text) {
  if (!text) return;
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export const isNotNullish = v => v !== null && v !== undefined;
export const isNullish = v => v === null || v === undefined;

export function isEmpty(val) {
  return val === null || val === undefined || val === ''
    || (Array.isArray(val) && val.length === 0);
}

export const jsonPretty = (text = '') => {
  try {
    const jsonObj = JSON.parse(text.trim());
    text = JSON.stringify(jsonObj, null, "\t");
  } catch (error) { }
  return text;
}

export function isIOS() {
  return navigator.userAgent.match(/ipad|ipod|iphone/i)
}

export const copyTextToClipBoardIOS = (el) => {
  if (typeof el === 'string') {
    el = document.querySelector(el);
  }
  if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
    const editable = el.contentEditable;
    const readOnly = el.readOnly;
    el.contentEditable = 'true';
    el.readOnly = 'false';
    const range = document.createRange();
    range.selectNodeContents(el);
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
    el.setSelectionRange(0, 999999);
    el.contentEditable = editable;
    el.readOnly = readOnly;

    document.execCommand('copy');
    message.success('คัดลอกแล้ว !');
  }
}

export const copyTextToClipBoard = (text = '', config = {}) => {
  text = text.trim();
  if (config.autoFormatJson) {
    text = jsonPretty(text);
  }

  const input = document.createElement('textarea');
  input.innerHTML = text;
  document.body.appendChild(input);
  input.select();
  let copySuccess = document.execCommand('copy');

  if (!copySuccess) {
    const editable = input.contentEditable;
    const readOnly = input.readOnly;
    input.contentEditable = 'true';
    input.readOnly = 'false';
    const range = document.createRange();
    range.selectNodeContents(input);
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
    input.setSelectionRange(0, 999999);
    input.contentEditable = editable;
    input.readOnly = readOnly;

    copySuccess = document.execCommand('copy');
  }

  if (copySuccess) {
    message.success('คัดลอกแล้ว !');
  } else {
    message.error('คัดลอกไม่สำเร็จ');
  }

  document.body.removeChild(input);
  return copySuccess;
}

export const scrollIntoView = (selector, opt = { behavior: 'smooth' }) => {
  const elem = typeof selector === 'string'
    ? document.querySelector(selector) : selector;
  if (elem) {
    elem.scrollIntoView(opt);
  }
}

export const formatPhoneNumber = (phone) => {
  if (!phone) return phone;
  return phone.trim().replace(/-/g, '').replace(/(\d{3})(\d{3})(\d{4})/, (_, f, s, t) => `${f}-${s}-${t}`)
}
// export const formatPhoneNumber = (phone) => {
//     if(!phone) return phone;
//     return phone.replace(/(\d{3})(\d{3})(\d{4})/, (_, f, s, t) => `${f}-${s}-${t}`)
// }

export const removeFormatPhoneNumber = (phone) => {
  if (!phone) return phone;
  return phone.trim().replace(/-/g, '')
}

export const isElementInViewport = (element, offset = {}) => {

  if (!element) return true;

  const {
    bottom: offsetBottom = 0,
  } = offset;

  var top = element.offsetTop;
  var left = element.offsetLeft;
  var width = element.offsetWidth;
  var height = element.offsetHeight;

  while (element.offsetParent) {
    element = element.offsetParent;
    top += element.offsetTop;
    left += element.offsetLeft;
  }

  // console.log({
  //     lay: { top, left, width, height },
  //     win: { y: window.pageYOffset, x: window.pageXOffset, inHeight: window.innerHeight, inWidth: window.innerWidth },
  //     topCheck: top < (window.pageYOffset + window.innerHeight),
  //     leftCheck: left < (window.pageXOffset + window.innerWidth),
  //     bottomCheck: (top + height) > (window.pageYOffset + offsetBottom),
  //     rightCheck: (left + width) > window.pageXOffset,
  // })

  return (
    top < (window.pageYOffset + window.innerHeight) &&
    left < (window.pageXOffset + window.innerWidth) &&
    (top + height) > (window.pageYOffset + offsetBottom) &&
    (left + width) > window.pageXOffset
  );
}

export const throttleSingleEvent = (func, interval) => {
  let timeout;
  return (...args) => {
    const context = this;
    const openIncomingCall = () => {
      timeout = false
    }
    if (!timeout) {
      func.apply(context, args);
      timeout = true;
      setTimeout(openIncomingCall, interval);
    }
  }
}

export const loadImage = (imgElem, image) => {
  if (!image || !imgElem) return;
  const reader = new FileReader();
  reader.onload = function (e) {
    imgElem.src = e.target.result;
  };
  reader.readAsDataURL(image);
}

export const resolve = (data, ...fields) => {
  if (!data) return;
  if (data && fields && !fields[0]) return data;

  return fields
    .reduce(
      (results, field) =>
      (field && field.indexOf('.') > -1
        ? [...results, ...field.split('.')]
        : [...results, field])
      , [])
    .reduce(
      (result, field) => (result ? result[field] : undefined),
      data
    );
}

export const getChangeValue = (x, config = {}) => {
  const { convertNumToStr, convertStrToNum, boolToTextConvertor } = config;

  // retrieve data
  let result;
  switch (typeof x) {
    case 'object':
      if (x) {
        if (Array.isArray(x)) {
          result = x;
        } else {
          const target = x.target;
          result = target.value !== undefined ? target.value : target.checked;
        }
      } else {
        result = x;
      }
      break;
    case 'string':
    case 'number':
    default: result = x;
  }

  // convert zone
  switch (typeof result) {
    case 'string':
      if (convertStrToNum && !Number.isNaN(+result))
        result = +result;
      break;
    case 'number':
      if (convertNumToStr)
        result = '' + result;
      break;
    case 'boolean':
      if (boolToTextConvertor)
        result = boolToTextConvertor(result)
      break;
    default: break;
  }

  return result;
}

export const handleResponse = (response, autoAlertError = true) => {
  const status = response.data.status
  if (status === "0") {
    return [response.data.result, undefined, response]
  } else {
    const errorMessage = response.data.message;
    if (autoAlertError) {
      Notification.alertError(errorMessage)
    }
    return [undefined, errorMessage, response]
  }
}

export const toOptions = (list, label, value) => {
  if (!list) return list;
  return list.map(data => {
    if (typeof data === 'object') {
      data.label = typeof label === 'function' ? data[label] : label(data);
      data.value = typeof value === 'function' ? data[value] : label(value);
    } else {
      data = { label: data, value: data }
    }
    return data;
  })
}

export const shuffle = (array) => {
  if (!array) return array;

  let counter = array.length;
  while (counter > 0) {
    let index = Math.floor(Math.random() * counter);
    counter--;
    let temp = array[counter];
    array[counter] = array[index];
    array[index] = temp;
  }

  return array;
}

export function addHiddenField(object, field, value) {
  if (!object[field]) {
    Object.defineProperty(object, field, {
      enumerable: false,
      writable: true,
      configurable: true,
      value: value,
    });
  } else {
    object[field] = value;
  }
}

export function onlyNumber(event) {
  if (!/\d/.test(event.key)) {
    event.preventDefault()
    return false;
  }
}
export function paginationAdapter(paginable, toServer = true) {
  if (toServer) {
    const page = paginable.page ?? paginable.current;
    const perPage = paginable.perPage ?? paginable.pageSize;
    paginable = { page, perPage };
    return paginable;
  } else {
    const current = paginable.current ?? paginable.page;
    const pageSize = paginable.pageSize ?? paginable.perPage;
    const total = paginable.total ?? paginable.totalItem;
    paginable = { ...paginable, current, pageSize, total };
    delete paginable.page;
    delete paginable.perPage;
    delete paginable.totalItem;
    return paginable;
  }
}

export function filterOutDuplicate(key, listA, listB) {
  if (!listA || !listB) return listB;
  const keysA = listA.map(itemA => itemA[key]);
  return listB.filter(itemB => !keysA.includes(itemB[key]))
}

export const toUpperCase = (object, fields = []) => {
  fields.forEach(field => {
    const original = object[field];
    if (original && typeof original === 'string') {
      object[field] = original.toUpperCase()
    }
  })
  return object;
}

export function stringFormat(text, params) {
  let currentIndex = 0;
  return text?.replace(/\{(\d+)?\}/g, (_, specificIndex) => {
    if (specificIndex) {
      currentIndex = +specificIndex + 1;
      return params?.[+specificIndex] ?? '';
    } else {
      return params?.[currentIndex++] ?? '';
    }
  }) ?? text;
};