import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import { AgentAction } from '../actions/Agent'
import { AgentActionType, REQUEST } from '../../const/ActionTypes'
import { AgentAPI } from '../services/Agent'
import Notification from '../../util/notification'
import { handleResponse } from '../../util/common-util'
import { UserAgentStatusLabel } from '../../const'
import { setLoading } from 'appRedux/actions/Common';
import dayjs from "dayjs";

function* getAgents() {
  try {
    const response = yield call(AgentAPI.getAgents);
    response.data.result.agents.forEach(agent => {
      if (agent.aliasName) {
        agent.fullname = `${agent.firstName} (${agent.aliasName})`;
      } else {
        agent.fullname = `${agent.firstName} ${agent.lastName}`;
      }
      agent.statusDesc = UserAgentStatusLabel[agent.status];
      agent.updateDateDisplay = dayjs(agent.updateDate).format('DD/MM/YYYY');
    })
    yield put(AgentAction.getAgentsSuccess(response.data.result));
  } catch (error) {
    yield put(AgentAction.getAgentsFailure(error));
  }
}

function* addNewAgent({ payload, resolver }) {
  try {
    const response = yield call(AgentAPI.addNewAgent, payload);
    const status = response.data.status
    if (status === "0") {
      yield put(AgentAction.addNewAgentSuccess(response.data.result));
      Notification.alertInfo("เพิ่มตัวแทนสำเร็จ")
      yield put(AgentAction.getAgents())
    } else {
      const errorMessage = response.data.message;
      yield put(AgentAction.addNewAgentFailure(errorMessage));
      Notification.alertError(errorMessage)
    }
    resolver(true)
  } catch (error) {
    resolver(false)
    yield put(AgentAction.addNewAgentFailure(error));
  }
}

function* saveNewAgent({ payload, resolver }) {
  try {
    const response = yield call(AgentAPI.saveNewAgent, payload);
    const status = response.data.status
    if (status === "0") {
      yield put(AgentAction.saveNewAgentSuccess());
      Notification.alertInfo("อัพเดทข้อมูลตัวแทนสำเร็จ")
      yield put(AgentAction.getAgents())
    } else {
      const errorMessage = response.data.message;
      yield put(AgentAction.saveNewAgentFailure(errorMessage));
      Notification.alertError(errorMessage)
    }
    resolver(true)
  } catch (error) {
    resolver(false)
    yield put(AgentAction.saveNewAgentFailure(error));
  }
}

function* changeQuota({ payload, resolver }) {
  try {
    yield put(setLoading(true))
    const response = yield call(AgentAPI.changeQuota, payload);
    const status = response.data.status
    if (status === "0") {
      yield put(AgentAction.changeQuotaSuccess(response.data.result));
      Notification.alertInfo("อัพเดทโควตาตัวแทนสำเร็จ")
      yield put(AgentAction.getAgents())
    } else {
      const errorMessage = response.data.message;
      yield put(AgentAction.changeQuotaFailure(errorMessage));
      Notification.alertError(errorMessage)
    }
    resolver(true)
  } catch (error) {
    resolver(false)
    yield put(AgentAction.changeQuotaFailure(error));
  } finally {
    yield put(setLoading(false))
  }
}

function* changeCredit({ payload, resolver }) {
  try {
    const response = yield call(AgentAPI.changeCredit, payload);
    const status = response.data.status
    if (status === "0") {
      yield put(AgentAction.changeCreditSuccess());
      Notification.alertInfo("อัพเดทเครดิตตัวแทนสำเร็จ")
      yield put(AgentAction.getAgents())
    } else {
      const errorMessage = response.data.message;
      yield put(AgentAction.changeCreditFailure(errorMessage));
      Notification.alertError(errorMessage)
    }
    resolver(true)
  } catch (error) {
    resolver(false)
    yield put(AgentAction.changeCreditFailure(error));
  }
}

function* getMinorAgent() {
  try {
    const [data, error] = handleResponse(yield call(AgentAPI.getMinorAgent))
    if (data) {
      yield put(AgentAction.getMinorAgentSuccess(data))
    } else {
      yield put(AgentAction.getMinorAgentFailure(error))
    }
  } catch (error) {
    yield put(AgentAction.getMinorAgentFailure(error));
  }
}

function* getMinorAgentOrderInfoList({ payload }) {
  try {
    const [data, error] = handleResponse(yield call(AgentAPI.getMinorAgentOrderInfoList, payload))
    if (data) {
      yield put(AgentAction.getMinorAgentOrderInfoListSuccess({ minorOrderInfo: data }))
    } else {
      yield put(AgentAction.getMinorAgentOrderInfoListFailure(error))
    }
  } catch (error) {
    yield put(AgentAction.getMinorAgentOrderInfoListFailure(error));
  }
}

export default [
  takeLatest(AgentActionType.GET_AGENT_LIST[REQUEST], getAgents),
  takeLeading(AgentActionType.ADD_AGENT[REQUEST], addNewAgent),
  takeLeading(AgentActionType.SAVE_AGENT[REQUEST], saveNewAgent),
  takeLeading(AgentActionType.CHANGE_QUOTA[REQUEST], changeQuota),
  takeLeading(AgentActionType.CHANGE_CREDIT[REQUEST], changeCredit),
  takeLatest(AgentActionType.GET_MINOR_AGENT[REQUEST], getMinorAgent),
  takeLatest(AgentActionType.GET_MINOR_AGENT_ORDER_INFO_LIST[REQUEST], getMinorAgentOrderInfoList),
]
