import { useCallback, useState } from "react";

const useControlVisible = (defaultVisible = false) => {
  const [visibleState, setVisibleState] = useState(defaultVisible);

  const show = useCallback((nState = {}) => 
    setVisibleState(oState => 
      typeof oState === 'object'
        ? ({ ...oState, ...nState, visible: true })
        : true
    ), []);
  const hide = useCallback((nState = {}) => 
    setVisibleState(oState => 
      typeof oState === 'object'
        ? ({ ...oState, ...nState, visible: false })
        : false
    ), []);

  return [visibleState, setVisibleState, [show, hide]]
}

export default useControlVisible;