import { useState } from 'react'
import { getChangeValue } from '../../util/common-util';

const useStateGroup = ({
  fields, defaultState = {}, converter = {}, effect = {},
}) => {

  const [state, setter] = useState(defaultState)

  const [utility] = useState({
    setter,
    init: () => setter(defaultState),
    clear: () => setter({}),
    filterTextOption: (input, option) => option.label.indexOf(input) >= 0,
  })

  const [setterGroup] = useState(() => {
    const setterFunctions = fields.map(field => ([
      field, 
      (...evt) => {
        let newValue;
        if ( converter[field] ) {
          if ( evt.length === 1 ) { // event
            newValue = converter[field](getChangeValue(evt[0]));
          } else { // custom values
            // console.log('old state:', {...state[field]})
            newValue = converter[field](...evt, state[field]);
          }
        } else {
          newValue = getChangeValue(evt[0]);
        }

        let effectValue = {};
        if (effect[field]) {
          effectValue = effect[field](newValue, state) ?? {};
        }

        setter(prev => ({ ...prev, ...effectValue, [field]: newValue }))
      }
    ]))
    .reduce((acc, [field, func]) => ({ ...acc, [field]: func }), {})

    return setterFunctions;
  });

  // console.log({
  //   state,
  //   setterGroup
  // })

  return [state, setterGroup, utility];

}

export default useStateGroup;
