import React, { memo } from "react";
import { Input, Modal, Checkbox, Form, Button } from "antd";
import { formatNumber } from "util/math";
import { onlyNumber } from "util/common-util";
import dayjs from "dayjs";

const AdjustCreditModal = ({ agent, quotaLimit, lotteryResultDate, visible, onClose, onSave }) => {

  const onFinish = ({ unLimit, credit }) => {
    const payload = {
      lotteryResultDate: dayjs(lotteryResultDate).format('DD-MM-YYYY'),
      credit: unLimit ? null : credit,
      username: agent.username,
    }
    onSave(payload)
  }

  return (
    <Modal
      title="เครดิต"
      footer={null}
      visible={visible}
      onCancel={onClose}
      centered
      destroyOnClose
      wrapClassName="adjust-credit-modal lottery-modal content-no-padding"
    >
      {agent && (
        <Form
          initialValues={{
            credit: agent.credit,
            unLimit: agent.credit === null,
          }}
          onFinish={onFinish}
          validateTrigger={['onSubmit']}
          preserve={false}
        >
          <div className="row justify-content-center">
            <div className="col-8 my-4 mx-5">
              <div className="form-group">
                <label className="text-gray"> ระบุจำนวนเครดิตที่ต้องการ </label>
                <Form.Item shouldUpdate noStyle>
                  {({ getFieldValue }) => {
                    const unLimit = getFieldValue('unLimit')
                    return (
                      <Form.Item name="credit"
                        normalize={value => value.replace(/\D/g, '')}
                        validateFirst
                        rules={!unLimit ? [
                          { required: true, message: 'กรุณาระบุจำนวน' },
                          { min: agent.creditBuy, message: `ต้องไม่ตำกว่า ${formatNumber(agent.creditBuy)} (ยอดขายเครดิต)` },
                        ] : undefined}>
                        <Input
                          className="form-control border-lottory input-focus"
                          type="number"
                          placeholder="ระบุจำนวนเครดิต"
                          onKeyPress={onlyNumber}
                          disabled={unLimit}
                        />
                      </Form.Item>
                    )
                  }}
                </Form.Item>
              </div>
              <div className="text-center">
                <Form.Item name="unLimit" valuePropName="checked">
                  <Checkbox className="mb-3">เครดิตไม่จำกัด</Checkbox>
                </Form.Item>
              </div>

              <div className="text-center bg-gray py-3" style={{ borderRadius: "30px" }} >
                <div className="text-gray">เครดิตทั้งหมด</div>
                <h3 className="text-main mb-0">{formatNumber(quotaLimit ?? 0)}</h3>
              </div>
            </div>
          </div>
          <div className="modal-footer d-block justify-content-center">
            <div className="row">
              <div className="col-6  pr-1">
                <button
                  type="button"
                  className="btn btn-lottery-gray btn-block my-2 "
                  onClick={onClose}
                >ยกเลิก</button>
              </div>
              <div className="col-6 pl-1">
                <Button
                  htmlType="submit"
                  className="btn btn btn-lottery btn-block my-2 "
                >บันทึก</Button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Modal>
  );
};

export default memo(AdjustCreditModal);
