import { ShopActionType, FAILURE, REQUEST, SUCCESS } from "const/ActionTypes";
import Immu from 'util/immu-util'
import _ from 'lodash'

const INIT_STATE = {
  loading: false,
  shopData: {},
};

export default (state = INIT_STATE, { type, payload = {} }) => {
  switch (type) {
    case ShopActionType.GET_SHOP[REQUEST]:
    case ShopActionType.SAVE_SHOP[REQUEST]:
    case ShopActionType.SHOP_LINE_CHANGE_STATUS[REQUEST]:
    case ShopActionType.SHOP_LINE_DELETE[REQUEST]:
      return { ...state, loading: true }

    case ShopActionType.GET_SHOP[SUCCESS]:
    case ShopActionType.SAVE_SHOP[SUCCESS]:
      return { ...state, loading: false, ...payload }

    case ShopActionType.GET_SHOP[FAILURE]:
    case ShopActionType.SAVE_SHOP[FAILURE]:
    case ShopActionType.SHOP_LINE_CHANGE_STATUS[FAILURE]:
    case ShopActionType.SHOP_LINE_DELETE[FAILURE]:
      return { ...state, loading: false, error: payload }

    case ShopActionType.SHOP_LINE_DELETE[SUCCESS]:
      return Immu.update(state,
        { action: Immu.MERGE, state: { loading: false } },  
        { 
          action: Immu.SET, 
          path: 'shopData.lineNotifications', 
          state: _.dropRightWhile(state.shopData.lineNotifications, { userId: payload.lineUserId })
        },
      )
    case ShopActionType.SHOP_LINE_CHANGE_STATUS[SUCCESS]:
      return Immu.update(state,
        { action: Immu.MERGE, state: { loading: false } },  
        {
          action: Immu.TOGGLE, 
          path: 'shopData.lineNotifications.{userId}', 
          state: ['send'],
          pathParams: { userId: payload.lineUserId },
        },
      )

    default:
      return state;
  }
}
