import React, { memo, useEffect, useState } from 'react'
import { Button, Modal } from 'antd';
import adsLotteryDrawDate from 'assets/images/ads/ads.jpg';
import registerAgent from 'assets/images/ads/register-agent.jpg';
import support from 'assets/images/ads/support.jpg';
import { useLocation } from 'react-router';
import _ from 'lodash'
import { motion } from 'framer-motion';
import { fadeVariants } from 'const/animation'

const AdsModal = (props) => {

  const whitelist = ['/signin', '/main']
  const location = useLocation()
  const [storage] = useState(() => Storage[props.storage] ?? Storage.window)
  const [sawAds, setSawAds] = useState([])
  const [loadedAds, setLoadedAds] = useState([])
  const ads = _.differenceBy(mappingAds[location.pathname]?.ads, sawAds, 'image')
  const currentAds = ads[0]

  useEffect(() => {
    if (sawAds.length === 3) storage['hide_ads'] = true
  }, [sawAds, storage])

  const onCloseAds = () => setSawAds(arr => _.compact([...arr, currentAds]))

  const renderCloseButton = () => (
    <ButtonDelay key="back"
      onClick={onCloseAds}
      trigger={sawAds}
      delay={1150}
      disabled={currentAds && !isImageLoaded(currentAds)}>
      ปิด
    </ButtonDelay>
  )

  const isWhitelistPage = () => whitelist.includes(location.pathname)
  const isThereAdsDoesNotShowLeft = () => ads.length > 0
  const shouldShowAdsModal = () => !storage['hide_ads'] && isWhitelistPage() && isThereAdsDoesNotShowLeft()
  const onAdImageLoaded = () => setLoadedAds(arr => _.compact([...arr, currentAds]))
  const isImageLoaded = (ad) => _.find(loadedAds, ['image', ad.image])

  return (
    <Modal
      title="ประกาศ"
      closable={false}
      maskClosable={false}
      wrapClassName="modal-p-0"
      visible={shouldShowAdsModal()}
      onOk={onCloseAds}
      onCancel={onCloseAds}
      footer={[renderCloseButton()]}
    >
      {ads.length > 0 && (
        currentAds?.link ? (
          <motion.a variants={fadeVariants} initial="hidden" animate="visible"
            href={currentAds?.link} target="_blank" rel="noopener noreferrer">
            <img
              className="w-100 user-select-none"
              src={currentAds?.image}
              onLoad={onAdImageLoaded}
              alt="advertises 1" />
          </motion.a>
        ) : (
          <motion.img variants={fadeVariants} initial="hidden" animate="visible"
            className="w-100 user-select-none"
            src={currentAds?.image}
            onLoad={onAdImageLoaded}
            alt="advertises 2" />
        )
      )}
    </Modal>
  )
}

export default memo(AdsModal);

const ButtonDelay = ({ trigger, delay, disabled: disabledProp, ...props }) => {
  const [disabled, setDisabled] = useState(true)
  useEffect(() => {
    setDisabled(true)
    const timeout = setTimeout(() => setDisabled(false), delay ?? 1000)
    return () => clearTimeout(timeout)
  }, [trigger, delay])

  return <Button {...props} disabled={disabled || disabledProp}>{props.children}</Button>
}

const registerUrl = 'https://line.me/ti/g2/AhAiwvbD4ZQ02EQ7o3_gOQ?utm_source=invitation&utm_medium=link_copy&utm_campaign=default'
const supportUrl = 'https://line.me/ti/p/%40REDTIGERSUPPORT'
const mappingAds = {
  '/signin': {
    ads: [
      {
        image: adsLotteryDrawDate,
      },
    ],
  },
  '/main': {
    ads: [
      {
        image: adsLotteryDrawDate,
      },
      {
        image: registerAgent,
        link: registerUrl,
      },
      {
        image: support,
        link: supportUrl,
      },
    ]
  },
}
const Storage = {
  window: window,
  session: sessionStorage,
  local: localStorage,
}