import { OrderActionType, REQUEST, SUCCESS, FAILURE } from '../../const/ActionTypes'

export const OrderAction = {
  initPage: payload => ({ type: OrderActionType.INIT_ORDER_LIST, payload }),
  pagingChange: payload => ({ type: OrderActionType.PAGING_ORDER_LIST, payload }),
  filterTextChange: payload => ({ type: OrderActionType.FILTER_TEXT_ORDER_LIST, payload }),
  searchFilterChange: payload => ({ type: OrderActionType.SEARCH_FILTER_ORDER_LIST, payload }),
  tableDataChange: payload => ({ type: OrderActionType.TABLE_DATA_ORDER_LIST, payload }),

  // Lottery
  getOrderList: payload => ({ type: OrderActionType.GET_ORDER_LIST[REQUEST], payload }),
  getOrderListSuccess: payload => ({ type: OrderActionType.GET_ORDER_LIST[SUCCESS], payload }),
  getOrderListFailure: error => ({ type: OrderActionType.GET_ORDER_LIST[FAILURE], error }),

  getOrderDetail: payload => ({ type: OrderActionType.GET_ORDER_DETAIL[REQUEST], payload }),
  getOrderDetailSuccess: payload => ({ type: OrderActionType.GET_ORDER_DETAIL[SUCCESS], payload }),
  getOrderDetailFailure: error => ({ type: OrderActionType.GET_ORDER_DETAIL[FAILURE], error }),

  addOrder: payload => ({ type: OrderActionType.ADD_ORDER[REQUEST], payload }),
  addOrderSuccess: payload => ({ type: OrderActionType.ADD_ORDER[SUCCESS], payload }),
  addOrderFailure: error => ({ type: OrderActionType.ADD_ORDER[FAILURE], error }),

  addOrderBigGroup: payload => ({ type: OrderActionType.ADD_ORDER_BIG_GROUP[REQUEST], payload }),
  addOrderBigGroupSuccess: payload => ({ type: OrderActionType.ADD_ORDER_BIG_GROUP[SUCCESS], payload }),
  addOrderBigGroupFailure: error => ({ type: OrderActionType.ADD_ORDER_BIG_GROUP[FAILURE], error }),

  buyReserveOrder: (payload, resolve) => ({ type: OrderActionType.BUY_RESERVE_ORDER[REQUEST], payload, resolve }),
  buyReserveOrderSuccess: payload => ({ type: OrderActionType.BUY_RESERVE_ORDER[SUCCESS], payload }),
  buyReserveOrderFailure: error => ({ type: OrderActionType.BUY_RESERVE_ORDER[FAILURE], error }),

  cancelReserve: (payload, resolve) => ({ type: OrderActionType.CANCEL_RESERVE[REQUEST], payload, resolve }),
  cancelReserveSuccess: payload => ({ type: OrderActionType.CANCEL_RESERVE[SUCCESS], payload }),
  cancelReserveFailure: error => ({ type: OrderActionType.CANCEL_RESERVE[FAILURE], error }),

  uploadProofPayment: payload => ({ type: OrderActionType.UPLOAD_PROOF_PAYMENT[REQUEST], payload }),
  uploadProofPaymentSuccess: payload => ({ type: OrderActionType.UPLOAD_PROOF_PAYMENT[SUCCESS], payload }),
  uploadProofPaymentFailure: error => ({ type: OrderActionType.UPLOAD_PROOF_PAYMENT[FAILURE], error }),

  // Lottery Box
  getOrderLotteryBoxList: payload => ({ type: OrderActionType.GET_ORDER_LOTTERY_BOX_LIST[REQUEST], payload }),
  getOrderLotteryBoxListSuccess: payload => ({ type: OrderActionType.GET_ORDER_LOTTERY_BOX_LIST[SUCCESS], payload }),
  getOrderLotteryBoxListFailure: error => ({ type: OrderActionType.GET_ORDER_LOTTERY_BOX_LIST[FAILURE], error }),

  getOrderLotteryBoxDetail: payload => ({ type: OrderActionType.GET_ORDER_LOTTERY_BOX_DETAIL[REQUEST], payload }),
  getOrderLotteryBoxDetailSuccess: payload => ({ type: OrderActionType.GET_ORDER_LOTTERY_BOX_DETAIL[SUCCESS], payload }),
  getOrderLotteryBoxDetailFailure: error => ({ type: OrderActionType.GET_ORDER_LOTTERY_BOX_DETAIL[FAILURE], error }),

  buyReserveOrderLotteryBox: (payload, resolve) => ({ type: OrderActionType.BUY_RESERVE_ORDER_LOTTERY_BOX[REQUEST], payload, resolve }),
  buyReserveOrderLotteryBoxSuccess: payload => ({ type: OrderActionType.BUY_RESERVE_ORDER_LOTTERY_BOX[SUCCESS], payload }),
  buyReserveOrderLotteryBoxFailure: error => ({ type: OrderActionType.BUY_RESERVE_ORDER_LOTTERY_BOX[FAILURE], error }),

  cancelReserveLotteryBox: (payload, resolve) => ({ type: OrderActionType.CANCEL_RESERVE_LOTTERY_BOX[REQUEST], payload, resolve }),
  cancelReserveLotteryBoxSuccess: payload => ({ type: OrderActionType.CANCEL_RESERVE_LOTTERY_BOX[SUCCESS], payload }),
  cancelReserveLotteryBoxFailure: error => ({ type: OrderActionType.CANCEL_RESERVE_LOTTERY_BOX[FAILURE], error }),

  changeOwner: payload => ({ type: OrderActionType.CHANGE_OWNER[REQUEST], payload }),
  changeOwnerSuccess: payload => ({ type: OrderActionType.CHANGE_OWNER[SUCCESS], payload }),
  changeOwnerFailure: payload => ({ type: OrderActionType.CHANGE_OWNER[FAILURE], payload }),
  // End Lottery Box
}