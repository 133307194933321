import React, { useState } from 'react'
import { Col, Empty, Modal, Row, Select } from 'antd'
import { useSelector } from 'react-redux'
import { AgentAPI } from '../../appRedux/services/Agent'
import dayjs from 'dayjs'
import { formatNumber } from '../../util/math'
import { clazz } from '../../util/common-util'
import { motion } from 'framer-motion';
import { CommonSelector } from 'appRedux/selector'

const AgentTransactionModal = ({
  visible, onClose, agentName,
}) => {

  const { drawDateList } = useSelector(CommonSelector.common);
  const [quotaHistory, setQuotaHistory] = useState([])
  const [drawDateSelected, setDrawDateSelected] = useState()

  async function onGetQuotaHistory(drawDate) {
    setDrawDateSelected(drawDate)
    const params = {
      agentName,
      date: dayjs(drawDate).format('DD-MM-YYYY'),
    }
    try {
      const response = await AgentAPI.getQuotaHistory(params);
      setQuotaHistory(response.data.result);
    } catch (error) {
      console.log(error)
    }
  }

  function onModalQuotaHistoryClose() {
    onClose()
    setQuotaHistory([])
    setDrawDateSelected(undefined)
  }

  return (
    <Modal title="ธุรกรรม" visible={visible} onCancel={onModalQuotaHistoryClose} centered
      wrapClassName="lottery-modal" footer={null}>
      <>
        <Row>
          <Col xs={24} sm={24} span={12} md={12} lg={12} xl={12} xxl={12}>
            <Select style={{ minWidth: 250 }} value={drawDateSelected}
              showSearch optionFilterProp="label"
              placeholder="งวดประจำวันที่"
              onChange={onGetQuotaHistory} allowClear
              filterOption={(input, option) => option.label.indexOf(input) >= 0}
            >
              {drawDateList.map(date => {
                const label = `งวดประจำวันที่ ${date.label}`
                return (
                  <Select.Option key={date.value} value={date.value} label={label}>
                    {label}
                  </Select.Option>
                )
              })}
            </Select>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <motion.div className="my-3" style={{ overflowY: 'auto', minHeight: 100, maxHeight: 280 }}
              variants={stagger} initial="initial" animate={quotaHistory.length > 0 ? "animate" : ''}>
              {quotaHistory.map(quota => {
                const isIncreaseQuota = quota.quantity > 0;
                const title = isIncreaseQuota ? 'เพิ่มโควตา' : 'ลดโควตา';
                const date = dayjs(quota.createDate).format('DD/MM/YYYY | HH:mm');
                const color = isIncreaseQuota ? 'text-green' : 'text-main';
                return (
                  <motion.div key={quota.createDate} className="bg-gray d-flex justify-content-between p-3 my-2"
                    variants={childVariants}>
                    <div>
                      <div>{title}</div>
                      <div className="text-gray font-small">{date}</div>
                    </div>
                    <div className={clazz('h5', color)}>
                      {formatNumber(quota.quantity)}
                    </div>
                  </motion.div>
                )
              })}
              { (!quotaHistory || quotaHistory.length === 0) && (
                <Empty description="ไม่มีรายการประวัติ" image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </motion.div>
          </Col>
        </Row>
        <div className="modal-footer justify-content-center">
          <button type="button" className="btn btn-lottery-gray px-5 my-2"
            onClick={onModalQuotaHistoryClose}>
            ปิด
          </button>
        </div>
      </>
    </Modal>
  )
}

export default React.memo(AgentTransactionModal);

const stagger = {
  animate: {
      transition: {
          staggerChildren: 0.05
      }
  }
}

const childVariants = {
  initial: { y: 40, opacity: 0 },
  animate: { y: 0, opacity: 1, transition: { duration: 0.3 } },
}