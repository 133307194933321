import React, { useRef, useEffect, memo } from 'react'
import { Space } from 'antd';
import useMount from 'components/hook/useMount';

const Pin = ({
  value, onChange, onPressEnter,
  emptyPlaceholder, autoFocus,
  pinInput, separatorPin, separatorPinEveryAt,
}) => {

  const refPins = useRef([]);

  useMount(() => {
    if (autoFocus && refPins.current[0]) refPins.current[0].focus()
  })

  useEffect(() => {
    refPins.current = refPins.current.slice(0, pinInput);
    const pinValue = value 
      ? value.replace(/\D/g, emptyPlaceholder).padStart(pinInput, emptyPlaceholder)
      : emptyPlaceholder.repeat(pinInput)
      refPins.current.forEach((el, index) => el.value = pinValue[index].replace(/\D/g, ''))
  }, [pinInput, value, emptyPlaceholder]);

  function getPinOutput() {
    return refPins.current.map(el => el.value || emptyPlaceholder).join('')
  }

  function onInputPinChange(index) {
    return (evt) => {
      if (evt.target.validity.valid) {
        const number = evt.target.value[evt.target.value.length - 1] || '';
        refPins.current[index].value = number;

        if (onChange) onChange(getPinOutput())
        if (number && number !== 0) {
          if (index < (pinInput - 1)) {
            focusPin(index + 1)
          }
        }
      }
    }
  }

  function focusPin(index) {
    if (index >= 0 && index <= (pinInput - 1))
      refPins.current[index].focus()
  }

  const isDeleteKey = key => +key === 8
  const isArrowLeftKey = key => +key === 37
  const isArrowRightKey = key => +key === 39
  const isEnterKey = key => +key === 13

  const removePreviousPin = index => {
    focusPin(index - 1)
    refPins.current[index].value = ''
  }

  function detectMovePinEvent(index) {
    return (evt) => {
      const key = evt.keyCode
      if (isDeleteKey(key) && !refPins.current[index].value && index !== 0)
        removePreviousPin(index)
      else if (isArrowLeftKey(key) && index !== 0)
        focusPin(index - 1)
      else if (isArrowRightKey(key) && index !== (pinInput - 1))
        focusPin(index + 1)
      else if (isEnterKey(key))
        onPressEnter && onPressEnter()
    }
  }

  function changePositionCursorToEnd(index) {
    return () => {
      setTimeout(() => {
        const cursorPosition = refPins.current[index].value.length;
        const inputRef = refPins.current[index]
        if (inputRef) {
          inputRef.selectionStart = cursorPosition
          inputRef.selectionEnd = cursorPosition
        }
      }, 0)
    }
  }

  function renderPin(index) {
    return (
      <input
        key={index}
        ref={el => refPins.current[index] = el}
        value={refPins.current[index]?.value ?? ''}
        className="form-control p-0 text-center" 
        // style={{ width: 32, height: 32 }}
        type="text"
        inputMode="numeric"
        pattern="[0-9]*"
        onChange={onInputPinChange(index)}
        onKeyDown={detectMovePinEvent(index)}
        onFocus={changePositionCursorToEnd(index)}
      />
    )
  }

  const shouldInsertPinSeparator = index =>
    index !== (pinInput - 1)
    && ((index + 1) % separatorPinEveryAt === 0)

  const renderSeparatorPin = key => <span key={`pin-sep-${key}`}>{separatorPin}</span>

  function renderPins() {
    const elements = []
    let countSep = 0
    for (let index = 0; index < pinInput; index++) {
      elements[index + countSep] = renderPin(index)
      if (shouldInsertPinSeparator(index))
        elements[index + ++countSep] = renderSeparatorPin(countSep)
    }
    return elements
  }

  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className="d-flex justify-content-center align-items-center">
        <Space>
          {renderPins()}
        </Space>
      </div>
    </div>
  )
}

export default memo(Pin);

Pin.defaultProps = {
  emptyPlaceholder: '*', // needs
  pinInput: 6,
  separatorPin: '-',
}