import { DefaultPaginable } from '../../const';
import { LotteryBoxActionType, REQUEST, SUCCESS, FAILURE } from 'const/ActionTypes';
import ImmuUtil from 'util/immu-util'
import { filterOutDuplicate } from 'util/common-util';

const initialStateSearch = {
  loading: false,
  lotteries: [],
  paginable: { ...DefaultPaginable }
}

const initialState = {
  ...initialStateSearch,
  cart: [],
};

export default (state = initialState, { type, payload = {}, error }) => {
  switch (type) {
    case LotteryBoxActionType.FETCH_LOTTERY_BOX[REQUEST]:
      return { ...state, ...initialStateSearch, loading: true }
    case LotteryBoxActionType.FETCH_LOTTERY_BOX_MORE:
      return { ...state, loading: true };

    case LotteryBoxActionType.FETCH_LOTTERY_BOX[SUCCESS]:
      return ImmuUtil.update(state, 
        { action: ImmuUtil.MERGE, state: { loading: false } },  
        { action: ImmuUtil.MERGE, path: 'paginable', state: payload.paginable },
        { action: ImmuUtil.PUSH, path: 'lotteries', state: filterOutDuplicate('id', state.lotteries, payload.lotteries) },
      )

    case LotteryBoxActionType.FETCH_LOTTERY_BOX[FAILURE]:
      return { ...state, loading: false, error }

    case LotteryBoxActionType.ADD_LOTTERY_BOX_TO_CART:
      return ImmuUtil.push(state, { path: 'cart', state: payload })

    case LotteryBoxActionType.REMOVE_LOTTERY_BOX_TO_CART:
      return ImmuUtil.arrayRemove(state, 
        { path: 'cart', state: { index: (boxsInCart) => boxsInCart.findIndex(box => box.id === payload.id) } })

    case LotteryBoxActionType.CLEAR_CART_LOTTERY_BOX:
      return { ...state, cart: [] };

    case LotteryBoxActionType.INIT_LOTTERY_LOTTERY_BOX:
      return { ...initialState };

    default: return state;
  }
}
