// import './wdyr';
import './polyfill'
import React from "react";
import ReactDOM from "react-dom";
import RedTigerApp from './RedTigerApp';
import { unregister } from './registerServiceWorker';
import { AppContainer } from 'react-hot-loader';
import { initializeGoogleAnalytics } from "./util/google-analytics";
import configureStore from './appRedux/store';
import 'event-source-polyfill/src/eventsource.min.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/redtiger-style.less'
// import registerServiceWorker from './registerServiceWorker';
// import { store } from './RedTigerApp'

// Wrap the rendering in a function:
const render = async Component => {
    await configureStore();
    ReactDOM.render(
        // Wrap App inside AppContainer
        <AppContainer>
            <Component />
        </AppContainer>,
        document.getElementById('root')
    );
};

// Do this once
// registerServiceWorker({
//     onSuccess: () => store.dispatch({ type: 'SERVICE_WORKER_INITIALIZE' }),
//     onUpdate: register => store.dispatch({ type: 'SERVICE_WORKER_UPDATED', payload: register }),
// });
unregister()

// Render once
render(RedTigerApp);

// Google Analytics
initializeGoogleAnalytics()

// Webpack Hot Module Replacement API
if (module.hot) {
    module.hot.accept('./RedTigerApp', () => {
        render(RedTigerApp);
    });
}
