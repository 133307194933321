import { ShopActionType, FAILURE, REQUEST, SUCCESS } from "../../const/ActionTypes";

export const ShopAction = {
    getShop: () => ({ type: ShopActionType.GET_SHOP[REQUEST] }),
    getShopSuccess: payload => ({ type: ShopActionType.GET_SHOP[SUCCESS], payload }),
    getShopFailure: payload => ({ type: ShopActionType.GET_SHOP[FAILURE], payload }),

    saveShop: payload => ({ type: ShopActionType.SAVE_SHOP[REQUEST], payload }),
    saveShopSuccess: payload => ({ type: ShopActionType.SAVE_SHOP[SUCCESS], payload }),
    saveShopFailure: payload => ({ type: ShopActionType.SAVE_SHOP[FAILURE], payload }),

    changeLineAccount: payload => ({ type: ShopActionType.SHOP_LINE_CHANGE_STATUS[REQUEST], payload }),
    changeLineAccountSuccess: payload => ({ type: ShopActionType.SHOP_LINE_CHANGE_STATUS[SUCCESS], payload }),
    changeLineAccountFailure: payload => ({ type: ShopActionType.SHOP_LINE_CHANGE_STATUS[FAILURE], payload }),

    deleteLineAccount: payload => ({ type: ShopActionType.SHOP_LINE_DELETE[REQUEST], payload }),
    deleteLineAccountSuccess: payload => ({ type: ShopActionType.SHOP_LINE_DELETE[SUCCESS], payload }),
    deleteLineAccountFailure: payload => ({ type: ShopActionType.SHOP_LINE_DELETE[FAILURE], payload }),

}