import { takeLatest, call, put } from 'redux-saga/effects'
import { OrderAction } from '../actions/Order'
import { OrderActionType, REQUEST } from '../../const/ActionTypes'
import { OrderAPI } from '../services/Order'
import Notification from '../../util/notification'

const getOrderList = function* (provider, { payload }) {
  try {
    const response = yield call(provider.api, payload)
    yield put(provider.success(response.data.result));
  } catch (error) {
    yield put(provider.failure(error));
  }
}

const getOrderDetail = function* (provider, { payload }) {
  try {
    const params = { txnId: payload }
    const response = yield call(provider.api, params)
    const orderDetail = { ...response.data.result }
    yield put(provider.success({ orderDetail }));
  } catch (error) {
    yield put(provider.failure(error));
  }
}

const buyReserveOrder = function* (provider, { payload, resolve }) {
  try {
    const params = {
      txnId: payload,
      action: 'confirm',
    }
    const response = yield call(provider.api, params);
    resolve(true);
    yield put(provider.success(response.data.result));
    Notification.alertInfo("ทำการจองสำเร็จ");
  } catch (error) {
    resolve(false);
    yield put(provider.failure(error));
    Notification.alertError("ทำการจองสำเร็จไม่สำเร็จ");
  }
}

const cancelReserve = function* (provider, { payload, resolve }) {
  try {
    const params = {
      txnId: payload,
      action: 'cancel',
    }
    const response = yield call(provider.api, params);
    resolve(true);
    yield put(provider.success(response.data.result));
    Notification.alertInfo("ยกเลิกการจองสำเร็จ");
  } catch (error) {
    resolve(false);
    yield put(provider.failure(error));
    Notification.alertError("ยกเลิกการจองไม่สำเร็จ");
  }
}

const uploadProofPayment = function* ({ payload }) {
  try {
    yield call(OrderAPI.uploadProofPayment, payload);
    yield put(OrderAction.uploadProofPaymentSuccess());
    Notification.alertInfo("อัพโหลดหลักฐานสำเร็จ");
  } catch (error) {
    yield put(OrderAction.uploadProofPaymentFailure(error));
    Notification.alertError("อัพโหลดหลักฐานไม่สำเร็จสำเร็จ");
  }
}

const changeOwner = function* ({ payload }) {
  try {
    yield call(OrderAPI.changeOwnerDetail, payload);
    yield put(OrderAction.changeOwnerSuccess(payload));
    Notification.alertInfo("เปลี่ยนข้อมูลสำเร็จ");
  } catch (error) {
    console.log(error)
    yield put(OrderAction.changeOwnerFailure(error));
    Notification.alertError("เปลี่ยนข้อมูลไม่สำเร็จ");
  }
}

export default [
  takeLatest(OrderActionType.GET_ORDER_LIST[REQUEST], getOrderList, { 
    api: OrderAPI.getOrderList,
    success: OrderAction.getOrderListSuccess,
    failure: OrderAction.getOrderListFailure,
  }),
  takeLatest(OrderActionType.GET_ORDER_DETAIL[REQUEST], getOrderDetail, { 
    api: OrderAPI.getOrderDetail,
    success: OrderAction.getOrderDetailSuccess,
    failure: OrderAction.getOrderDetailFailure,
  }),
  takeLatest(OrderActionType.BUY_RESERVE_ORDER[REQUEST], buyReserveOrder, { 
    api: OrderAPI.updateOrder,
    success: OrderAction.buyReserveOrderSuccess,
    failure: OrderAction.buyReserveOrderFailure,
  }),
  takeLatest(OrderActionType.CANCEL_RESERVE[REQUEST], cancelReserve, { 
    api: OrderAPI.updateOrder,
    success: OrderAction.cancelReserveSuccess,
    failure: OrderAction.cancelReserveFailure,
  }),
  takeLatest(OrderActionType.UPLOAD_PROOF_PAYMENT[REQUEST], uploadProofPayment),

  // Lottery Box
  takeLatest(OrderActionType.GET_ORDER_LOTTERY_BOX_LIST[REQUEST], getOrderList, { 
    api: OrderAPI.getOrderLotteryBoxList,
    success: OrderAction.getOrderLotteryBoxListSuccess,
    failure: OrderAction.getOrderLotteryBoxListFailure,
  }),
  takeLatest(OrderActionType.GET_ORDER_LOTTERY_BOX_DETAIL[REQUEST], getOrderDetail, { 
    api: OrderAPI.getOrderLotteryBoxDetail,
    success: OrderAction.getOrderLotteryBoxDetailSuccess,
    failure: OrderAction.getOrderLotteryBoxDetailFailure,
  }),
  takeLatest(OrderActionType.BUY_RESERVE_ORDER_LOTTERY_BOX[REQUEST], buyReserveOrder, { 
    api: OrderAPI.updateOrderLotteryBox,
    success: OrderAction.buyReserveOrderLotteryBoxSuccess,
    failure: OrderAction.buyReserveOrderLotteryBoxFailure,
  }),
  takeLatest(OrderActionType.CANCEL_RESERVE_LOTTERY_BOX[REQUEST], cancelReserve, { 
    api: OrderAPI.updateOrderLotteryBox,
    success: OrderAction.cancelReserveLotteryBoxSuccess,
    failure: OrderAction.cancelReserveLotteryBoxFailure,
  }),
  takeLatest(OrderActionType.CHANGE_OWNER[REQUEST], changeOwner)
];