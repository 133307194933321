import { Select } from 'antd';
import useDrawDate from 'components/hook/useDrawDate';
import React, { memo } from 'react'
import { clazz } from 'util/common-util';

const SelectDrawDate = ({ className, value, onChange, initFirst, ...props }) => {

  const drawDateList = useDrawDate(initFirst ? onChange : null);

  return (
    <Select className={clazz('select-draw-date', className)}
      options={drawDateList}
      placeholder="งวดประจำวันที่"
      allowClear
      {...props}
      value={value}
      onChange={onChange}
    />
  )
}

export default memo(SelectDrawDate);

SelectDrawDate.defaultProps = {
  initFirst: true,
}