import React, { useEffect, useRef } from 'react'
import ImgCrop from 'antd-img-crop';
import { Upload } from 'antd'
import { clazz, loadImage } from '../../util/common-util';
import { Edit3 } from 'react-feather'
import { PlusOutlined } from '@ant-design/icons'
import { motion } from 'framer-motion';
import styled from 'styled-components'
import 'antd/es/slider/style';

const AgentUploadImageProfile = ({
  defaultImage, images, setImages
}) => {

  const pictureProfileRef = useRef()
  const ignoreDefaultImageRef = useRef()

  useEffect(() => {
    if (images) {
      loadImage(pictureProfileRef.current, images[0]);
    }
  }, [images])

  function beforeUploadImageProof(file) {
    ignoreDefaultImageRef.current = true
    setImages([file])
    return false;
  }

  function onProfileClick() {
    document.querySelector('.upload-agent-picture .ant-upload-select-picture-card > .ant-upload').click()
  }

  function onRemovePictureProfile() {
    ignoreDefaultImageRef.current = true
    setImages(undefined)
  }

  const shouldIgnoreDefaultImage = () => ignoreDefaultImageRef.current
  const hasImageDisplay = () => (!shouldIgnoreDefaultImage() && defaultImage) || images
  const hasImageUploaded = () => images

  return (
    <>
      <ImgCrop rotate grid quality={1} modalOk="ยืนยัน" modalCancel="ยกเลิก" modalTitle="รูปโปรไฟล์">
        <Upload className={clazz('upload-agent-picture', { cond: hasImageDisplay(), clz: 'd-none' })}
          listType="picture-card" // text, picture or picture-card
          fileList={images}
          beforeUpload={beforeUploadImageProof}
        >
          <div className="icon-text d-flex flex-column justify-content-center align-items-center">
            <PlusOutlined />
            <span className="text-upload">รูปโปรไฟล์</span>
          </div>
        </Upload>
      </ImgCrop>
      { hasImageDisplay() && (
        <div className="text-center img-profile-cover position-relative">
          <Image
            src={defaultImage}
            ref={pictureProfileRef}
            variants={imageProfileVariants} initial="hidden" animate="visible"
            className="img-fluid img-profile"
            alt="preview profile" />
          {hasImageUploaded() && <span className="remove-x-profile" onClick={onRemovePictureProfile}>x</span>}
          <span className="change-profile" onClick={onProfileClick}>
            <Edit3 size={15} />
          </span>
        </div>
      )}
    </>
  )
}

export default React.memo(AgentUploadImageProfile)

export const imageProfileVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
}

const Image = styled(motion.img)`
  object-fit: cover;
`