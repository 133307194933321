import { LotteryClientAPI } from './LotteryCreator'

export const OrderAPI = {
  getOrderList: payload => LotteryClientAPI.post('/order', payload),
  getOrderDetail: params => LotteryClientAPI.get('/order', { params }),
  addOrder: payload => LotteryClientAPI.post('/order/add', payload),
  updateOrder: params => LotteryClientAPI.get('/order/update', { params }), // action= cancel | confirm
  uploadProofPayment: payload => LotteryClientAPI.post('/order/upload', payload),

  getOrderLotteryBoxList: payload => LotteryClientAPI.post('/order/boxSet', payload),
  getOrderLotteryBoxDetail: params => LotteryClientAPI.get('/order/boxSet', { params }),
  updateOrderLotteryBox: params => LotteryClientAPI.get('/order/boxSet/update', { params }), // action= cancel | confirm

  changeOwnerDetail: payload => LotteryClientAPI.post('/order/change/owner', payload)
}