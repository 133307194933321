import { call, takeLatest } from "redux-saga/effects";
import { NotificationActionType } from '../../const/ActionTypes'
import { NotificationAPI } from '../services/Notification'

function* initNotification() {
  try {
    yield call(NotificationAPI.initNotification);
  } catch (error) {
    console.log('init error')
  }
}

function* readNotification({ payload }) {
  try {
    yield call(NotificationAPI.readNotification, { notificationId: payload });
  } catch (error) {
    console.log('read error')
  }
}

export default [
  takeLatest(NotificationActionType.READ_NOTIFICATION, readNotification),
  takeLatest(NotificationActionType.INIT_NOTIFICATION, initNotification),
]
