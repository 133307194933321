import React from 'react'
import { clazz } from '../../../util/common-util'

const Align = {
    start: 'align-items-start',
    center: 'align-items-center',
    end: 'align-items-end',
}

export default React.memo(({
    icon, label, align,
}) => {
    return (
        <div className={clazz('d-flex', Align[align])}>
            {icon}<span className="mx-2">{label}</span>
        </div>
    )
})