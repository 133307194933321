import { AgentActionType, FAILURE, REQUEST, SUCCESS } from "../../const/ActionTypes";

const INIT_STATE = {
  loading: false,
  getQuotaLoading: false,
  agents: [],
  minorList: [],
  detail: {},
  minorOrderInfo: {
    orders: [],
    total: 0,
  }
};

export default (state = INIT_STATE, { type, payload = {} }) => {
  switch (type) {
    case AgentActionType.GET_AGENT_LIST[REQUEST]:
    case AgentActionType.GET_MINOR_AGENT_ORDER_INFO_LIST[REQUEST]:
    case AgentActionType.ADD_AGENT[REQUEST]:
    case AgentActionType.SAVE_AGENT[REQUEST]:
      return { ...state, loading: true }

    case AgentActionType.GET_AGENT_LIST[SUCCESS]:
    case AgentActionType.ADD_AGENT[SUCCESS]:
    case AgentActionType.SAVE_AGENT[SUCCESS]:
    case AgentActionType.GET_MINOR_AGENT_ORDER_INFO_LIST[SUCCESS]:
      return { ...state, loading: false, ...payload }

    case AgentActionType.GET_AGENT_LIST[FAILURE]:
    case AgentActionType.ADD_AGENT[FAILURE]:
    case AgentActionType.SAVE_AGENT[FAILURE]:
    case AgentActionType.GET_MINOR_AGENT_ORDER_INFO_LIST[FAILURE]:
      return { ...state, loading: false, error: payload }

    case AgentActionType.GET_MINOR_AGENT[SUCCESS]:
      return { ...state, minorList: payload }
    case AgentActionType.GET_MINOR_AGENT[FAILURE]:
      return { ...state, error: payload }

    default:
      return state;
  }
}
