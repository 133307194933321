import { all, call, fork, put, takeEvery, takeLatest, takeLeading } from "redux-saga/effects";
import {
  REFRESH_TOKEN,
  REQUEST,
  SIGNIN_USER,
  SIGNOUT_USER,
} from "const/ActionTypes";
import { refreshTokenFailure, refreshTokenSuccess, removeUserLocal, showAuthMessage, userSignInSuccess } from "../../appRedux/actions/Auth";
import { AuthAPI } from "../services/Auth";
import { _TOKEN_ } from "../../const";
import { push } from 'react-router-redux';
import { UNAUTHEN_401 } from "../../const/ActionTypes";
import Notification from '../../util/notification'

function* signInUserWithEmailPassword({ payload }) {
  try {
    let response = yield call(AuthAPI.signin, payload);
    const { accessToken, tokenType } = response.data.result;
    const token = `${tokenType} ${accessToken}`;
    localStorage.setItem(_TOKEN_, token);
    yield put(userSignInSuccess(token));
    yield put(push('/main'));
    // yield call(watchSessionTerminate)
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* refreshToken() {
  try {
    let response = yield call(AuthAPI.refreshToken);
    const { accessToken, tokenType } = response.data.result;
    const token = `${tokenType} ${accessToken}`;
    localStorage.setItem(_TOKEN_, token);
    yield put(refreshTokenSuccess(token));
  } catch (error) {
    yield put(refreshTokenFailure(error));
  }
}

// function* watchSessionTerminate() {
//   if (!!localStorage.getItem(_TOKEN_)) {
//     console.log('watchSessionTerminate')
//     yield take(UNAUTHEN_401);
//     console.log('Expire go to signin page')
//     yield call(unAuthen401)
//   }
// }

function* signOut() {
  try {
    localStorage.removeItem(_TOKEN_);
    yield put(push('/signin'));
    yield put(userSignInSuccess());
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* unAuthen401() {
  if (localStorage.getItem(_TOKEN_)) {
    yield put(removeUserLocal());
    yield call(signOut);
    Notification.alertError('ไม่อยู่หน้าจอเป็นเวลานาน กรุณาล็อคอินใหม่');
  }
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(signOutUser),
    takeLatest(UNAUTHEN_401, unAuthen401),
    takeLeading(REFRESH_TOKEN[REQUEST], refreshToken),
    // take(WATCH_SESSION_TERMINATE, watchSessionTerminate)
  ]);
}
