import React, { useState, useEffect, useContext } from 'react'
import { Col, Drawer, Row, Form, Input, Select, Divider, Button, Tooltip, Switch, Checkbox, Grid } from 'antd'
import { FileText, User, CreditCard } from 'react-feather'
import IconLabel from '../../components/misc/IconLabel'
import { useDispatch, useSelector } from 'react-redux'
import { getBankList } from '../../appRedux/actions/Common'
import useMount from '../../components/hook/useMount'
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons'
import { AgentContext } from './index'
import AgentUploadImageProfile from './AgentUploadImageProfile';
import { onlyNumber, removeFormatPhoneNumber } from '../../util/common-util';
import { AgentSelector, CommonSelector } from 'appRedux/selector'

const IconProps = {
  size: 18,
  style: { color: "#dc3545" }
}

const { Option } = Select;
const { useBreakpoint } = Grid;

export default React.memo(({
  editSelfProfile, formName = "agent_detail"
}) => {

  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const [form] = Form.useForm();
  const [pictureProfiles, setPictureProfiles] = useState()
  const [requiredConfirmPassword, setRequiredConfirmPassword] = useState(false)
  const bankList = useSelector(CommonSelector.bankList);
  const {
    username: currentUserId,
    levelValue: level,
    price: minPricePerLottery = 80,
  } = useSelector(AgentSelector.agentDetail);
  const { agent, drawerVisible, closeDrawer, onSaveAgent } = useContext(AgentContext)

  const isEdit = !!agent;
  const defaultImageProfile = isEdit ? agent.avatar : undefined
  const isUserPending = isEdit && agent.status === 'P';
  const isEditDownLine = isEdit && !editSelfProfile;
  const isHead = level === 1;

  useMount(() => dispatch(getBankList()))

  useEffect(() => {
    if (drawerVisible) {
      if (isEdit) {
        const cloneData = { ...agent };
        cloneData.phone = removeFormatPhoneNumber(cloneData.phone)
        cloneData.handicapped = cloneData.handicapped === 'Y'
        cloneData.status = cloneData.status === 'A'
        form.setFieldsValue(cloneData)
      } else {
        // Add
        form.setFieldsValue({ quotaType: 'N' })
      }
    } else {
      form.resetFields()
      setPictureProfiles(undefined)
      setRequiredConfirmPassword(false)
    }
  }, [drawerVisible, agent, form, isEdit, isEditDownLine])

  const onFinish = values => {
    // Edit
    values.handicapped = values.handicapped ? 'Y' : 'N';

    if (isEdit) {
      if (isEditDownLine) {
        // delete values.password;

        if (!isUserPending) {
          values.status = values.status ? 'A' : 'C';
        } else {
          values.status = 'P'
        }
      }
    }
    // Add
    else {
      if (level === 1) {
        values.username = values.username.toUpperCase()
      } else {
        values.username = `${currentUserId}${values.username}`.toUpperCase()
      }
      values.status = 'P' // create user default pending status
    }

    const formData = new FormData();
    if (pictureProfiles) {
      formData.append('file', pictureProfiles[0]);
    }
    formData.append('agent', JSON.stringify(values));

    onSaveAgent(formData, isEdit)
  }

  function getNegativeAgentStatusLabel() {
    if (isEditDownLine && agent.status === 'P') {
      return "รออนุมัติ"
    } else {
      return "ล็อค"
    }
  }

  function onValuesChange(changedValues, _) {
    if (Object.keys(changedValues).includes('password')) {
      if (changedValues.password) {
        setRequiredConfirmPassword(true);
      } else {
        setRequiredConfirmPassword(false);
        form.setFieldsValue({ confirmPassword: undefined })
      }
    }
  }

  return (
    <Drawer title="แก้ข้อมูลตัวแทน" placement="right" onClose={closeDrawer} visible={drawerVisible}
      className="agent-drawer" width={450}
      getContainer={false}
      destroyOnClose
    >
      <Form form={form} layout="vertical"
        className="rt-form"
        preserve={false}
        name={formName}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        autoComplete="off"
        scrollToFirstError
      >
        <Row gutter={[22, 15]}>
          {/* ข้อมูลส่วนตัว */}
          <Col span={24}>
            <IconLabel icon={<FileText {...IconProps} />} label="ข้อมูลส่วนตัว" />
          </Col>
          <Col span={24} className="text-center">
            <AgentUploadImageProfile defaultImage={defaultImageProfile} images={pictureProfiles} setImages={setPictureProfiles} />
          </Col>
          <Col span={24}>
            <Form.Item label="ชื่อแบรนด์" rules={[{ required: true, message: 'กรุณาระบุชื่อแบรนด์' }]} name="brandName">
              <Input placeholder="ชื่อแบรนด์" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} span={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item label="ชื่อเล่น" name="aliasName">
              <Input placeholder="ชื่อเล่น" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} span={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item label="ทุพลภาพ" name="handicapped" valuePropName="checked">
              <Checkbox>ใช่ / ไม่</Checkbox>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} span={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item label="ชื่อ" rules={[{ required: true, message: 'กรุณาระบุชื่อ' }]} name="firstName">
              <Input placeholder="ชื่อ" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} span={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item label="นามสกุล" rules={[{ required: true, message: 'กรุณาระบุนามสกุล' }]} name="lastName">
              <Input placeholder="นามสกุล" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} span={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item label="เบอร์โทรศัพท์"
              normalize={value => value.replace(/-/g, '')}
              rules={[
                { required: true, message: 'กรุณาระบุเบอร์โทรศัพท์' },
                { len: 10, message: 'เบอร์์โทรศัพท์ต้องมีจำนวน 10 หลัก' },
              ]} name="phone">
              <Input placeholder="เบอร์โทรศัพท์" onKeyPress={onlyNumber} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} span={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item label="รหัสบัตรประชาชน"
              rules={[
                { required: true, message: 'กรุณาระบุรหัสบัตรประชาชน' },
                { len: 13, message: 'รหัสบัตรประชาชนต้องมีจำนวน 13 หลัก' },
              ]} name="idCard">
              <Input placeholder="รหัสบัตรประชาชน" minLength={13} maxLength={13} onKeyPress={onlyNumber} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} span={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item label="ราคาต่อใบ"
              rules={!editSelfProfile ? [
                { required: true, message: 'กรุณาระบุราคาต่อใบ' },
                {
                  validator(_, value) {
                    if (+value > minPricePerLottery) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error(`ราคาต่อใบต้องมากกว่า ${minPricePerLottery} บาท`))
                  }
                }
              ] : []} name="price">
              <Input placeholder="ราคาต่อใบ" maxLength={3} onKeyPress={onlyNumber} disabled={editSelfProfile} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} span={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item label="เฟซบุ๊ก" name="facebook">
              <Input placeholder="เฟซบุ๊ก" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} span={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item label="ไลน์ ไอดี" name="lineId">
              <Input placeholder="ไลน์ ไอดี" />
            </Form.Item>
          </Col>

          {/* ข้อมูลประวัติผู้ใช้ */}
          <Col span={24} className="mt-3">
            <IconLabel icon={<User {...IconProps} />} label="ข้อมูลบัญชีผู้ใช้" />
          </Col>
          <Col xs={24} sm={24} span={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item name="username" label="ชื่อเข้าสู่ระบบ"
              normalize={value => value.toUpperCase()}
              rules={[
                { required: !isEdit, message: 'กรุณาระบุชื่อเข้าสู่ระบบ' },
                { max: isHead ? 50 : 50 - (currentUserId ?? '').length, message: `ต้องไม่เกิน ${isHead ? 50 : 50 - (currentUserId ?? '').length} ตัวอักษร` },
                ...(level !== 1 && !isEdit ? [
                  { pattern: /^[A-Za-z]{1}\d+$/, message: 'ต้องขึ้นต้นด้วยตัวอักษร 1 ตัว แล้วตามด้วยตัวเลขกี่ตัวกี่ตวก็ได้' }
                ] : [
                  { min: 5, message: 'ต้องไม่ต่ำกว่า 5 ตัวอักษร' },
                ])
              ]}
            >
              <Input className="text-uppercase" autoComplete="nope"
                placeholder="ชื่อเข้าสู่ระบบ"
                maxLength={isHead ? 50 : 50 - (currentUserId ?? '').length}
                prefix={!isHead && !isEdit ? <span className="text-gray user-select-none">{currentUserId}</span> : undefined}
                disabled={isEdit}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} span={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item label="รหัสผ่าน" rules={[
              { required: !isEdit, message: 'กรุณาระบุรหัสผ่าน' },
              { min: 8, message: 'รหัสผ่านอย่างน้อย 8 หลัก' },
              { max: 20, message: 'รหัสผ่านมากสุด 20 หลัก' },
            ]} name="password">
              <Input.Password autoComplete="new-password" placeholder="รหัสผ่าน"
                iconRender={passVisible => (passVisible ? <EyeTwoTone twoToneColor="#dc3545" /> : <EyeInvisibleOutlined />)}
                minLength={8} maxLength={20} />
            </Form.Item>
          </Col>
          {requiredConfirmPassword && (
            <Col offset={screens.md ? 12 : undefined} xs={24} sm={24} span={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item name="confirmPassword" label="ยืนยันรหัสผ่าน" dependencies={['password']}
                rules={[
                  { required: true, message: 'กรุณายืนยันรหัสผ่าน' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('รหัสผ่านไม่ตรงกัน!'));
                    },
                  })]}
              >
                <Input.Password autoComplete="new-password" placeholder="ยืนยันรหัสผ่าน"
                  iconRender={passVisible =>
                    (passVisible ? <EyeTwoTone twoToneColor="#dc3545" /> : <EyeInvisibleOutlined />)}
                  maxLength={20} />
              </Form.Item>
            </Col>
          )}
          <Col xs={24} sm={24} span={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item name="quotaType" label="ประเภทโควต้า"
              rules={[{ required: true, message: 'กรุณาระบุประเภทโควต้า' }]}>
              <Select placeholder="ประเภทโควต้า" disabled={isEdit} allowClear >
                <Select.Option value="N">ปกติ</Select.Option>
                <Select.Option value="C">เครดิต</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          {isEditDownLine && (
            <Col xs={24} sm={24} span={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item label="สถานะ" name="status" valuePropName="checked">
                <Switch checkedChildren="ปกติ"
                  unCheckedChildren={getNegativeAgentStatusLabel()}
                  disabled={agent.status === 'P'} />
              </Form.Item>
            </Col>
          )}

          {/* ข้อมูลบัญชีการเงิน */}
          <Col span={24} className="mt-3">
            <IconLabel icon={<CreditCard {...IconProps} />} label="ข้อมูลบัญชีการเงิน" />
          </Col>
          <Col xs={24} sm={24} span={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item label="ชื่อธนาคาร" rules={[{ required: !editSelfProfile, message: 'กรุณาระบุธนาคาร' }]} name="bankPrefix">
              <Select
                // options={bankList}
                optionFilterProp="label"
                placeholder="ชื่อธนาคาร" allowClear
                filterOption={(input, option) => option.label.indexOf(input) >= 0}
                disabled={editSelfProfile}
              >
                {bankList.map(bank => (
                  <Option key={bank.id}
                    value={bank.bankPrefix} label={bank.thaiName}>
                    <Tooltip placement="topLeft" title={bank.thaiName}>
                      <div className="bank-option">
                        <img alt="bank icon" className="bank-color" src={'/images/bank/' + bank.bankPrefix + '.svg'} style={{ backgroundColor: bank.color }} />
                        <span>{bank.thaiName}</span>
                      </div>
                    </Tooltip>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} span={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item label="เลขบัญชี" rules={[{ required: !editSelfProfile, message: 'กรุณาระบุเลขบัญชี' }]} name="bankAccNo">
              <Input placeholder="เลขบัญชี" type="number" disabled={editSelfProfile} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} span={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item label="ชื่อบัญชี" rules={[{ required: !editSelfProfile, message: 'กรุณาระบุชื่อบัญชี' }]} name="bankAccName">
              <Input placeholder="ชื่อบัญชี" disabled={editSelfProfile} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} span={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item label="พร้อมเพย์" name="promptPay">
              <Input placeholder="พร้อมเพย์" type="number" disabled={editSelfProfile} />
            </Form.Item>
          </Col>
          <Divider type="horizontal" className="my-2" />
        </Row>
        <Row gutter={[22, 15]} className="mt-3">
          <Form.Item shouldUpdate noStyle>
            {(form) => {
              return (
                <Col key="save_bth" {...saveBtnCol}>
                  <Button className="btn btn-lottery btn-primary btn-lg btn-block" 
                    htmlType="submit"
                    disabled={ form.getFieldsError().filter(({ errors }) => errors.length).length > 0 }>
                    บันทึกข้อมูล
                  </Button>
                </Col>
              )
            }}
          </Form.Item>
          <Col key="cancel_bth" {...cancelBtnCol}>
            <Button className="btn btn-lottery-gray btn-block btn-lg h-100 f-1rem" onClick={closeDrawer}>ยกเลิก</Button>
          </Col>
        </Row>
      </Form>
    </Drawer>
  )
})

const saveBtnCol = {
  xs: { order: 2, span: 24 },
  sm: { order: 2, span: 24 },
  md: { order: 1, span: 11 },
  lg: { order: 1, span: 11 },
  xl: { order: 1, span: 11 },
  xxl: { order: 1, span: 11 },
}
const cancelBtnCol = {
  xs: { order: 1, span: 24 },
  sm: { order: 1, span: 24 },
  md: { order: 2, span: 11 },
  lg: { order: 2, span: 11 },
  xl: { order: 2, span: 11 },
  xxl: { order: 2, span: 11 },
}