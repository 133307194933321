import { takeLatest, takeLeading, call, put, select, debounce } from 'redux-saga/effects'
import { LotteryAction } from '../actions/Lottery'
import { LotteryActionType, LotteryBoxActionType, REQUEST } from '../../const/ActionTypes'
import { LotteryAPI } from '../services/Lottery'
import { LotterySelector, LotteryBoxSelector } from '../selector'
import { convertToFullThaiFormat, convertToFullEngFormat, handleResponse, paginationAdapter, isNullish, isNotNullish } from '../../util/common-util'

const searchLottery = function* ({ payload }) {
  try {
    let response = yield call(LotteryAPI.searchLottery, payload)
    if (response.data.result.lotteries.length === 0) {
      if (isLatest3DigitSearch(payload)) {
        const newCriteria = { ...payload, ...swapLast3DigitToFront(payload) }
        response = yield call(LotteryAPI.searchLottery, newCriteria)
        if (response.data.result.lotteries.length > 0) {
          const result = prepareLotteryData(response.data.result, newCriteria)
          yield put(LotteryAction.confirmSwapDigitOpen({ ...result, latestCriteria: newCriteria }))
          yield put(LotteryAction.searchLotteryFailure())
        }
      }
      yield put(LotteryAction.searchLotteryFailure())
    } else {
      const result = prepareLotteryData(response.data.result, payload)
      yield put(LotteryAction.searchLotterySuccess({ ...result, latestCriteria: payload }))
    }
  } catch (error) {
    yield put(LotteryAction.searchLotteryFailure(error));
  }
}

const confirmSwapLotteryDigit = function* () {
  const saveState = yield select(LotterySelector.modalFrontDigitState)
  yield put(LotteryAction.searchLotterySuccess(saveState.state))
  yield put(LotteryAction.confirmSwapDigitClose())
}

const getLotteryWinners = function* ({ payload }) {
  try {
    const [result, error] = handleResponse(yield call(LotteryAPI.getLotteryWinners, payload))
    if (result) {
      yield put(LotteryAction.getLotteryWinnersSuccess({ lotteryWinner: result }))
    } else {
      yield put(LotteryAction.getLotteryWinnersFailure(error))
    }
  } catch (error) {
    yield put(LotteryAction.getLotteryWinnersFailure(error));
  }
}

const resetSearchLotteryToPage1 = function* () {
  const latestCriteria = yield select(LotterySelector.latestCriteria);
  const payload = { ...latestCriteria, page: 1 }
  yield put(LotteryAction.searchLottery(payload))
}

// const searchLotteryMoreChecker = function* ({ payload }) {
//   const { currentLoadingPage, totalPage } = yield select(LotterySelector.lottery);
//   if (
//     payload.page !== currentLoadingPage &&
//     payload.page <= totalPage
//   ) {
//     yield put(LotteryAction.searchMoreLotteryQueue(payload));
//   }
// }

// const searchMoreQueue = function* () {
//   const channelLoadMore = yield actionChannel(LotteryActionType.SEARCH_LOTTERY_MORE_QUEUE)
//   while (true) {
//     const { payload } = yield take(channelLoadMore)
//     yield call(searchLotteryMore, payload)
//   }
// }

const searchLotteryMore = function* () {
  try {
    const { latestCriteria, totalPage } = yield select(LotterySelector.lottery);
    const nextPage = (latestCriteria.page ?? 0) + 1
    // console.log('load more:', {
    //   nextPage, 
    //   totalPage,
    //   loadMore: nextPage <= totalPage,
    // })
    if (nextPage <= totalPage) {
      yield put(LotteryAction.loadingLotteryMore());
      // console.log('search lottery page:', nextPage)
      const payload = { ...latestCriteria, page: nextPage }
      const response = yield call(LotteryAPI.searchLottery, payload);
      const result = prepareLotteryData(response.data.result, payload);
      yield put(LotteryAction.searchLotteryMoreSuccess({ ...result, latestCriteria: payload }));
    }
  } catch (error) {
    yield put(LotteryAction.searchLotteryMoreFailure(error));
    yield put(LotteryAction.setDesirePage(undefined))
  }
}

function prepareLotteryData(result, payload) {
  const dateTh = convertToFullThaiFormat(payload.currentLotteryDate);
  const dateEn = convertToFullEngFormat(payload.currentLotteryDate);
  const bigSetLotteries = getBigSetLottery(result.bigGroupQuantity).map(
    amount => ({
      amount,
      dateTh, dateEn,
      numbers: result.bigGroupText,
    })
  );
  result.bigSetLotteries = bigSetLotteries;

  return result;
}

function getBigSetLottery(bigGroupQuantity) {
  if (bigGroupQuantity >= 5) {
    const bigGroups = [1000, 500, 200, 100, 50, 20, 10, 5];
    if (!bigGroups.includes(bigGroupQuantity)) {
      bigGroups.unshift(bigGroupQuantity)
    }
    return bigGroups.reduce((acc, amount) => {
      return ((bigGroupQuantity >= amount && acc.push(amount), acc));
    }, []);
  } else {
    return [];
  }
}

const fetchLotteryBox = function* () {
  try {
    let paginable = paginationAdapter(yield select(LotteryBoxSelector.paginable));
    const response = yield call(LotteryAPI.fetchLotteryBox, paginable);
    const { lotteries, totalItem: total } = response.data.result;
    yield put(LotteryAction.fetchLotteryBoxSuccess({ lotteries, paginable: { total } }));
  } catch (error) {
    yield put(LotteryAction.fetchLotteryBoxFailure(error));
  }
}

const fetchLotteryBoxMore = function* ({ resolve }) {
  try {
    let paginable = paginationAdapter(yield select(LotteryBoxSelector.paginable));
    paginable.page = paginable.page + 1;
    const response = yield call(LotteryAPI.fetchLotteryBox, paginable);
    const { lotteries, totalItem: total } = response.data.result;
    if (lotteries.length > 0) {
      paginable = paginationAdapter({ ...paginable, total }, false);
      yield put(LotteryAction.fetchLotteryBoxSuccess({ lotteries, paginable }));
    } else {
      yield put(LotteryAction.fetchLotteryBoxFailure());
    }
  } catch (error) {
    yield put(LotteryAction.fetchLotteryBoxFailure(error));
  } finally {
    resolve();
  }
}

const orderLotteryBox = function* ({ payload, resolve }) {
  try {
    const response = yield call(LotteryAPI.orderLotteryBox, payload);
    resolve(response.data.result);
    yield put(LotteryAction.orderLotteryBoxSuccess());
  } catch (error) {
    yield put(LotteryAction.orderLotteryBoxFailure(error));
    resolve();
  }
}

const getSummaryGroup = function* ({ payload }) {
  try {
    const params = { digits: payload, from: 'L' }
    const response = yield call(LotteryAPI.groupSummary, params)
    let i = 0;
    let newlist = [];
    let perviousArr = 0;
    if (response.data.result) {
      while (i <= 99) {
        if (response.data.result.items[i - perviousArr] && i === response.data.result.items[i - perviousArr].digitNumbers) {
          newlist.push(response.data.result.items[i - perviousArr]);
          // perviousArr = 0;
        } else {
          newlist.push({ numbers: i.toString().padStart(2, '0'), count: 0, digitNumbers: i });
          perviousArr++;
        }
        i++;
      }
      response.data.result.items = newlist;
    }
    yield put(LotteryAction.getGroupSummarySuccess({ groupSummary: response.data.result }));
  } catch (error) {
    yield put(LotteryAction.getGroupSummaryFailure(error));
  }
}

const getFeeAgent = function* ({ payload }) {
  try {
    const [data, error] = handleResponse(yield call(LotteryAPI.getFeeAgent, payload))
    if (data) {

      yield put(LotteryAction.getFeeAgentSuccess({ feeAgentList: data }))
    } else {
      yield put(LotteryAction.getFeeAgentFailure(error))
    }
  } catch (error) {
    yield put(LotteryAction.getFeeAgentFailure(error));
  }
}


export default [
  takeLatest([
    LotteryActionType.SEARCH_LOTTERY[REQUEST],
    LotteryActionType.SEARCH_LOTTERY_SET,
  ], searchLottery),
  takeLatest(LotteryBoxActionType.FETCH_LOTTERY_BOX[REQUEST], fetchLotteryBox),
  takeLatest(LotteryBoxActionType.FETCH_LOTTERY_BOX_MORE, fetchLotteryBoxMore),
  debounce(500, LotteryActionType.GET_LOTTERY_WINNERS[REQUEST], getLotteryWinners),
  takeLeading(LotteryActionType.SEARCH_LOTTERY_MORE[REQUEST], searchLotteryMore),
  takeLeading(LotteryActionType.RESET_SEARCH_LOTTERY_TO_PAGE_1, resetSearchLotteryToPage1),
  takeLeading(LotteryBoxActionType.ORDER_LOTTERY_BOX[REQUEST], orderLotteryBox),
  takeLatest(LotteryActionType.GET_GROUP_SUMMARY[REQUEST], getSummaryGroup),
  debounce(500, LotteryActionType.GET_FEE_AGENT[REQUEST], getFeeAgent),
  takeLatest(LotteryActionType.CONFIRM_SWAP_LOTTERY_DIGIT, confirmSwapLotteryDigit),
];

const isLatest3DigitSearch = (criteria) => {
  return isNullish(criteria['digit1']) && isNullish(criteria['digit2']) && isNullish(criteria['digit3'])
    && isNotNullish(criteria['digit4']) && isNotNullish(criteria['digit5']) && isNotNullish(criteria['digit6'])
}

const swapLast3DigitToFront = (criteria) => {
  return {
    digit1: criteria['digit4'],
    digit2: criteria['digit5'],
    digit3: criteria['digit6'],
    digit4: null,
    digit5: null,
    digit6: null,
  }
}