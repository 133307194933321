import { LotteryActionType, LotteryBoxActionType, REQUEST, SUCCESS, FAILURE } from '../../const/ActionTypes'

export const LotteryAction = {
  initLottery: () => ({ type: LotteryActionType.INIT_LOTTERY }),

  searchLottery: payload => ({ type: LotteryActionType.SEARCH_LOTTERY[REQUEST], payload }),
  searchLotterySuccess: payload => ({ type: LotteryActionType.SEARCH_LOTTERY[SUCCESS], payload }),
  searchLotteryFailure: payload => ({ type: LotteryActionType.SEARCH_LOTTERY[FAILURE], payload }),

  confirmSwapDigit: () => ({ type: LotteryActionType.CONFIRM_SWAP_LOTTERY_DIGIT }),
  confirmSwapDigitOpen: payload => ({ type: LotteryActionType.CONFIRM_SWAP_LOTTERY_DIGIT_OPEN, payload }),
  confirmSwapDigitClose: () => ({ type: LotteryActionType.CONFIRM_SWAP_LOTTERY_DIGIT_CLOSE }),

  searchLotterySet: payload => ({ type: LotteryActionType.SEARCH_LOTTERY_SET, payload }),

  searchLotteryMore: payload => ({ type: LotteryActionType.SEARCH_LOTTERY_MORE[REQUEST], payload }),
  searchLotteryMoreSuccess: payload => ({ type: LotteryActionType.SEARCH_LOTTERY_MORE[SUCCESS], payload }),
  searchLotteryMoreFailure: payload => ({ type: LotteryActionType.SEARCH_LOTTERY_MORE[FAILURE], payload }),

  getLotteryWinners: payload => ({ type: LotteryActionType.GET_LOTTERY_WINNERS[REQUEST], payload }),
  getLotteryWinnersSuccess: payload => ({ type: LotteryActionType.GET_LOTTERY_WINNERS[SUCCESS], payload }),
  getLotteryWinnersFailure: payload => ({ type: LotteryActionType.GET_LOTTERY_WINNERS[FAILURE], payload }),

  addLotteryToCart: payload => ({ type: LotteryActionType.ADD_LOTTERY_TO_CART, payload }),
  addBigGroupLotteryToCart: payload => ({ type: LotteryActionType.ADD_BIG_GROUP_LOTTERY_TO_CART, payload }),
  removeLotteryFromCart: payload => ({ type: LotteryActionType.REMOVE_LOTTERY_FROM_CART, payload }),
  clearCart: () => ({ type: LotteryActionType.CLEAR_CART }),
  loadingLotteryMore: () => ({ type: LotteryActionType.SEARCH_LOTTERY_MORE_LOADING }),
  resetSearchPageLotteryToPage1: () => ({ type: LotteryActionType.RESET_SEARCH_LOTTERY_TO_PAGE_1 }),

  // Lottery Box
  fetchLotteryBox: () => ({ type: LotteryBoxActionType.FETCH_LOTTERY_BOX[REQUEST] }),
  fetchLotteryBoxSuccess: payload => ({ type: LotteryBoxActionType.FETCH_LOTTERY_BOX[SUCCESS], payload }),
  fetchLotteryBoxFailure: error => ({ type: LotteryBoxActionType.FETCH_LOTTERY_BOX[FAILURE], error }),
  fetchLotteryBoxMore: resolve => ({ type: LotteryBoxActionType.FETCH_LOTTERY_BOX_MORE, resolve }),
  addLotteryBoxToCart: payload => ({ type: LotteryBoxActionType.ADD_LOTTERY_BOX_TO_CART, payload }),
  removeLotteryBoxFromCart: payload => ({ type: LotteryBoxActionType.REMOVE_LOTTERY_BOX_TO_CART, payload }),
  clearCartLotteryBox: () => ({ type: LotteryBoxActionType.CLEAR_CART_LOTTERY_BOX }),
  initLotteryBox: () => ({ type: LotteryBoxActionType.INIT_LOTTERY_LOTTERY_BOX }),

  orderLotteryBox: (payload, resolve) => ({ type: LotteryBoxActionType.ORDER_LOTTERY_BOX[REQUEST], payload, resolve }),
  orderLotteryBoxSuccess: payload => ({ type: LotteryBoxActionType.ORDER_LOTTERY_BOX[SUCCESS], payload }),
  orderLotteryBoxFailure: error => ({ type: LotteryBoxActionType.ORDER_LOTTERY_BOX[FAILURE], error }),

  // Summary Group number lotto
  getGroupSummary: payload => ({ type: LotteryActionType.GET_GROUP_SUMMARY[REQUEST], payload }),
  getGroupSummarySuccess: payload => ({ type: LotteryActionType.GET_GROUP_SUMMARY[SUCCESS], payload }),
  getGroupSummaryFailure: payload => ({ type: LotteryActionType.GET_GROUP_SUMMARY[FAILURE], payload }),

  getFeeAgent: payload => ({ type: LotteryActionType.GET_FEE_AGENT[REQUEST], payload }),
  getFeeAgentSuccess: payload => ({ type: LotteryActionType.GET_FEE_AGENT[SUCCESS], payload }),
  getFeeAgentFailure: payload => ({ type: LotteryActionType.GET_FEE_AGENT[FAILURE], payload }),
}