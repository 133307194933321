import { LotteryActionType, REQUEST, SUCCESS, FAILURE } from '../../const/ActionTypes';

const INIT_SEARCH = {
  loading: false,
  lotteries: [],
  bigSetLotteries: [],
  numberSet: '',
  // bigGroupText: undefined,
  bigGroupQuantity: undefined,
  totalPage: 0,
  currentPage: 1,
  totalItem: 0,
  perPage: 20,
  requestMorePage: 1,
  latestCriteria: {},
  lotteryWinner: {
    rewards: [],
  },
  groupSummary: {},
  feeAgentList: [],
}

const INIT_STATE = {
  ...INIT_SEARCH,
  cart: [],
  modalFrontDigitState: {
    visible: false, state: null
  }
};

export default (state = INIT_STATE, { type, payload = {}, error }) => {
  switch (type) {
    case LotteryActionType.SEARCH_LOTTERY[REQUEST]:
      return { ...state, ...INIT_SEARCH, numberSet: state.numberSet, loading: true }
    case LotteryActionType.SEARCH_LOTTERY_SET:
      return { ...state, ...INIT_SEARCH, numberSet: payload.numberSet, loading: true }

    case LotteryActionType.GET_LOTTERY_WINNERS[REQUEST]:
    case LotteryActionType.GET_GROUP_SUMMARY[REQUEST]:
    case LotteryActionType.GET_FEE_AGENT[REQUEST]:
      return { ...state, loading: true }

    case LotteryActionType.SEARCH_LOTTERY[SUCCESS]:
    case LotteryActionType.GET_LOTTERY_WINNERS[SUCCESS]:
    case LotteryActionType.GET_FEE_AGENT[SUCCESS]:
      return { ...state, loading: false, ...payload }

    case LotteryActionType.GET_GROUP_SUMMARY[SUCCESS]:
      return { ...state, loading: false, ...payload }

    case LotteryActionType.SEARCH_LOTTERY_MORE_LOADING:
      return { ...state, loading: true }
    case LotteryActionType.SEARCH_LOTTERY_MORE[SUCCESS]:
      const { lotteries, ...rest } = payload;
      const alreadyFetch = state.lotteries.map(l => l.id);
      return {
        ...state,
        ...rest,
        loading: false,
        lotteries: [
          ...state.lotteries,
          ...lotteries.filter(l => !alreadyFetch.includes(l.id)),
        ]
      }

    case LotteryActionType.SEARCH_LOTTERY_MORE[FAILURE]:
    case LotteryActionType.SEARCH_LOTTERY[FAILURE]:
    case LotteryActionType.GET_LOTTERY_WINNERS[FAILURE]:
    case LotteryActionType.GET_GROUP_SUMMARY[FAILURE]:
    case LotteryActionType.GET_FEE_AGENT[FAILURE]:
      return { ...state, loading: false, error }

    case LotteryActionType.ADD_LOTTERY_TO_CART: {
      const nCart = [...state.cart].filter(l => !l.lotteryNumber.includes('*'));
      const addIndex = state.cart.findIndex(lottery => lottery.lotteryNumber === payload.lotteryNumber)
      if (addIndex >= 0) {
        nCart[addIndex].amount = payload.amount;
        return { ...state, cart: nCart }
      } else {
        return { ...state, cart: [...nCart, payload] }
      }
    }

    case LotteryActionType.ADD_BIG_GROUP_LOTTERY_TO_CART: {
      return { ...state, cart: [payload] }
    }

    case LotteryActionType.REMOVE_LOTTERY_FROM_CART: {
      const removeIndex = state.cart.findIndex(lottery => lottery.lotteryNumber === payload)
      state.cart.splice(removeIndex, 1)
      return { ...state, cart: [...state.cart] }
    }

    case LotteryActionType.INIT_LOTTERY: {
      return INIT_STATE;
    }

    case LotteryActionType.CLEAR_CART: {
      return { ...state, cart: [] };
    }

    case LotteryActionType.CONFIRM_SWAP_LOTTERY_DIGIT_OPEN:
      return {
        ...state,
        modalFrontDigitState: {
          visible: true,
          state: payload,
        }
      }

    case LotteryActionType.CONFIRM_SWAP_LOTTERY_DIGIT_CLOSE:
      return {
        ...state,
        modalFrontDigitState: {
          visible: false,
          state: null,
        }
      }

    default:
      return state;
  }
}
