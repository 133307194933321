import { Col, Form, Modal, Row, DatePicker, Collapse, Spin } from 'antd';
import React, { memo, useEffect, useState } from 'react'
import { CaretRightOutlined } from "@ant-design/icons";
import SelectDrawDate from 'components/SelectDrawDate';
import useStateGroup from 'components/hook/useStateGroup';
import { AgentAPI } from 'appRedux/services/Agent'
import dayjs from 'dayjs';
import _ from 'lodash'
import { formatNumber } from 'util/math';

const { RangePicker } = DatePicker;
const { Panel } = Collapse;

const AgentQuotaSummary = ({ visible, onCancel }) => {
  return (
    <Modal
      title="สรุปยอดทั้งหมด"
      visible={visible}
      maskClosable={false}
      onCancel={onCancel}
      destroyOnClose
      footer
    >
      <QuotaSummary />
    </Modal>
  )
}

export default memo(AgentQuotaSummary);

const QuotaSummary = memo(() => {

  const [filterValue, onFilterChange] = useStateGroup(defaultState)
  const [{ loading, quotaSummary }, setQuotaSummary] = useState({ loading: false, quotaSummary: null })

  useEffect(() => {
    const getQuotaSummary = async (params) => {
      try {
        setQuotaSummary({ loading: true, quotaSummary: null })

        const response = await AgentAPI.getQuotaSummary(params)
        const history = response.data.result
        const summary = history.reduce((agents, log) => {
          const index = _.findIndex(agents, ['agent', log.agent])
          const agent = index >= 0 ? agents[index] : { agent: log.agent, sumQuota: 0, logs: [] }
          log.quantity *= -1 // reserver aspect log
          agent.logs.push(log)
          agent.sumQuota += log.quantity
          if (index === -1) agents.push(agent)
          return agents
        }, [])

        setQuotaSummary({ loading: false, quotaSummary: summary })
      } catch (error) {
        setQuotaSummary({ loading: false, quotaSummary: null })
      }
    }
    const { date, dateFromTo } = filterValue
    if (date || (dateFromTo && (dateFromTo[0] || dateFromTo[1]))) {
      const params = {
        date: date ? dayjs(date).format('DD-MM-YYYY') : undefined,
        from: dateFromTo?.[0] ? dayjs(dateFromTo[0]).format('DD-MM-YYYY') : undefined,
        to: dateFromTo?.[1] ? dayjs(dateFromTo[1]).format('DD-MM-YYYY') : undefined,
      }
      getQuotaSummary(params)
    }
  }, [filterValue])

  return (
    <>
      <Row gutter={16}>
        <Col span={24} sm={12}>
          <Form layout="vertical">
            <Form.Item label="งวด">
              <SelectDrawDate value={filterValue['date']} onChange={onFilterChange['date']} initFirst />
            </Form.Item>
          </Form>
        </Col>
        <Col span={24}>
          <Form layout="vertical">
            <Form.Item label="เลือกวันที่">
              <RangePicker
                className="w-100"
                value={filterValue['dateFromTo']}
                onChange={onFilterChange['dateFromTo']}
                placeholder={['วันที่เริ่ม', 'ถึงวันที่']}
                format={'DD-MM-YYYY'} />
            </Form.Item>
          </Form>
        </Col>
      </Row>
      {loading
        ? <div className="flex-main"><Spin /></div>
        : <QuotaSummaryContent quotaSummary={quotaSummary} />}
    </>
  )
})

const QuotaSummaryContent = memo(({ quotaSummary }) => {

  return (
    <div className="content-summary-agent">
      <Collapse
        // defaultActiveKey={["1"]}
        className="site-collapse-custom-collapse"
        expandIcon={({ isActive }) => (<CaretRightOutlined rotate={isActive ? 90 : 0} />)}
      >
        {quotaSummary?.map(summary => {
          return (
            <Panel key={summary.agent} header={summary.agent}>
              {summary.logs.map(log => (
                <div key={log.createDate} className="bg-gray d-flex justify-content-between p-3 mb-2">
                  <div>
                    <div>{log.quantity > 0 ? 'เพิ่มโควต้า' : 'ลดโควต้า'}</div>
                    <div className="text-gray font-small">
                      {dayjs(log.createDate).format('DD/MM/YYYY HH:mm:ss')}
                    </div>
                  </div>
                  <h5>{formatNumber(log.quantity)}</h5>
                </div>
              ))}
              <div className="bg-gray-strong  align-items-center d-flex justify-content-between p-2 mb-2">
                <div>
                  <div>รวม</div>
                </div>
                <h5 className="text-main">{formatNumber(summary.sumQuota)}</h5>
              </div>
            </Panel>
          )
        })}
      </Collapse>
    </div>
  )
})

const defaultState = {
  fields: ['date', 'dateFromTo'],
}