import { DashBoardActionType, FAILURE, REQUEST, SUCCESS } from "../../const/ActionTypes";

const INIT_STATE = {
    loading: false,
    result: [],
};

export default (state = INIT_STATE, { type, payload = {} }) => {
    switch (type) {
        case DashBoardActionType.GET_NEWS[REQUEST]: {
            return { ...state, loading: true }
        }

        case DashBoardActionType.GET_NEWS[SUCCESS]: {
            return { ...state, loading: false, ...payload }
        }

        case DashBoardActionType.GET_NEWS[FAILURE]: {
            return { ...state, loading: false, error: payload }
        }

        default:
            return state;
    }
}
