import { call, put, takeLatest } from "redux-saga/effects";
import { CustomerAction } from '../actions/Customer'
import { CustomerActionType, REQUEST } from '../../const/ActionTypes'
import { CustomerAPI } from '../services/Customer'
import dayjs from "dayjs";

function* getCustomers() {
    try {
        const response = yield call(CustomerAPI.getCustomers);
        response.data.result.forEach(customer => {
            customer.updateDateDisplay = dayjs(customer.updateDate).format('DD/MM/YYYY HH:mm:ss');
        })
        yield put(CustomerAction.getCustomersSuccess(response.data));
    } catch (error) {
        yield put(CustomerAction.getCustomersFailure(error));
    }
}

export default [
    takeLatest(CustomerActionType.GET_CUSTOMER_LIST[REQUEST], getCustomers),
]