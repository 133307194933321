export const REQUEST = 'REQUEST';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';

function createRequestTypes(base) {
  return [REQUEST, SUCCESS, FAILURE].reduce((acc, type) => {
    acc[type] = `${base}_${type}`;
    return acc
  }, {});
}

export const CommonActionType = {
  LOADER_CENTRAL: 'LOADER_CENTRAL',
  GET_BANK_LIST: createRequestTypes('GET_BANK_LIST'),
  GET_DRAW_DATE: createRequestTypes('GET_DRAW_DATE'),
}

// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';
export const SHOW_MENU_BAR = 'SHOW_MENU_BAR';
export const HIDE_MENU_BAR = 'HIDE_MENU_BAR';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SET_TOKEN = 'SET_TOKEN';
export const REFRESH_TOKEN = createRequestTypes('REFRESH_TOKEN');
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const REMOVE_USER_LOCAL = 'REMOVE_USER_LOCAL';
export const UNAUTHEN_401 = 'UNAUTHEN_401';
export const WATCH_SESSION_TERMINATE = 'WATCH_SESSION_TERMINATE';

//Sticky
export const GET_STICKY = 'GET_STICKY';
export const NOTES_UPDATE = 'NOTES_UPDATE';
export const FETCH_ALL_NOTES_SUCCESS = 'FETCH_ALL_NOTES_SUCCESS';
export const UPDATE_ALL_NOTES_SUCCESS = 'UPDATE_ALL_NOTES_SUCCESS';

//Contact
export const GET_All_CONTACT_SUCCESS = 'GET_All_CONTACT_SUCCESS';
export const ON_ADD_CONTACT_SUCCESS = 'ON_ADD_CONTACT_SUCCESS';
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS';
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';

// Agent
export const AgentActionType = {
  GET_AGENT_LIST: createRequestTypes('GET_AGENT_LIST'),
  ADD_AGENT: createRequestTypes('ADD_AGENT'),
  SAVE_AGENT: createRequestTypes('SAVE_AGENT'),
  GET_QUOTA_HISTORY: createRequestTypes('GET_QUOTA_HISTORY'),
  CHANGE_QUOTA: createRequestTypes('CHANGE_QUOTA'),
  CHANGE_CREDIT: createRequestTypes('CHANGE_CREDIT'),
  GET_MINOR_AGENT: createRequestTypes('GET_MINOR_AGENT'),
  GET_MINOR_AGENT_ORDER_INFO_LIST: createRequestTypes('GET_MINOR_AGENT_ORDER_INFO_LIST'),
}

// Lottery
export const LotteryActionType = {
  INIT_LOTTERY: 'INIT_LOTTERY',
  CLEAR_CART: 'CLEAR_CART',
  SEARCH_LOTTERY: createRequestTypes('SEARCH_LOTTERY'),
  SEARCH_LOTTERY_SET: 'SEARCH_LOTTERY_SET',
  SEARCH_LOTTERY_MORE: createRequestTypes('SEARCH_LOTTERY_MORE'),
  SEARCH_LOTTERY_MORE_LOADING: 'SEARCH_LOTTERY_MORE_LOADING',
  RESET_SEARCH_LOTTERY_TO_PAGE_1: 'RESET_SEARCH_LOTTERY_TO_PAGE_1',
  ADD_LOTTERY_TO_CART: 'ADD_LOTTERY_TO_CART',
  ADD_BIG_GROUP_LOTTERY_TO_CART: 'ADD_BIG_GROUP_LOTTERY_TO_CART',
  REMOVE_LOTTERY_FROM_CART: 'REMOVE_LOTTERY_FROM_CART',
  GET_LOTTERY_WINNERS: createRequestTypes('GET_LOTTERY_WINNERS'),
  GET_GROUP_SUMMARY: createRequestTypes('GET_GROUP_SUMMARY'),
  GET_FEE_AGENT: createRequestTypes('GET_FEE_AGENT'),
  CONFIRM_SWAP_LOTTERY_DIGIT: 'CONFIRM_SWAP_LOTTERY_DIGIT',
  CONFIRM_SWAP_LOTTERY_DIGIT_OPEN: 'CONFIRM_SWAP_LOTTERY_DIGIT_OPEN',
  CONFIRM_SWAP_LOTTERY_DIGIT_CLOSE: 'CONFIRM_SWAP_LOTTERY_DIGIT_CLOSE',
}

// Customer
export const CustomerActionType = {
  GET_CUSTOMER_LIST: createRequestTypes('GET_CUSTOMER_LIST'),
  ADD_LOTTERY_TO_CART: 'ADD_LOTTERY_TO_CART',
  REMOVE_LOTTERY_FROM_CART: 'REMOVE_LOTTERY_FROM_CART',
}

// Order
export const OrderActionType = {
  GET_ORDER_LIST: createRequestTypes('GET_ORDER_LIST'),
  GET_ORDER_DETAIL: createRequestTypes('GET_ORDER_DETAIL'),
  ADD_ORDER: createRequestTypes('ADD_ORDER'),
  ADD_ORDER_BIG_GROUP: createRequestTypes('ADD_ORDER_BIG_GROUP'),
  LIST_ORDER: createRequestTypes('LIST_ORDER'),
  BUY_RESERVE_ORDER: createRequestTypes('BUY_RESERVE_ORDER'),
  CANCEL_RESERVE: createRequestTypes('CANCEL_RESERVE'),
  UPLOAD_PROOF_PAYMENT: createRequestTypes('UPLOAD_PROOF_PAYMENT'),

  GET_ORDER_LOTTERY_BOX_LIST: createRequestTypes('GET_ORDER_LOTTERY_BOX_LIST'),
  GET_ORDER_LOTTERY_BOX_DETAIL: createRequestTypes('GET_ORDER_LOTTERY_BOX_DETAIL'),
  BUY_RESERVE_ORDER_LOTTERY_BOX: createRequestTypes('BUY_RESERVE_ORDER_LOTTERY_BOX'),
  CANCEL_RESERVE_LOTTERY_BOX: createRequestTypes('CANCEL_RESERVE_LOTTERY_BOX'),
  CHANGE_OWNER: createRequestTypes('CHANGE_OWNER'),

  INIT_ORDER_LIST: 'INIT_ORDER_LIST',
  PAGING_ORDER_LIST: 'PAGING_ORDER_LIST',
  FILTER_TEXT_ORDER_LIST: 'FILTER_TEXT_ORDER_LIST',
  SEARCH_FILTER_ORDER_LIST: 'SEARCH_FILTER_ORDER_LIST',
  TABLE_DATA_ORDER_LIST: 'TABLE_DATA_ORDER_LIST',
}

// DashBoard
export const DashBoardActionType = {
  GET_NEWS: createRequestTypes('GET_NEWS'),
}

// SHOP
export const ShopActionType = {
  GET_SHOP: createRequestTypes('GET_SHOP'),
  SAVE_SHOP: createRequestTypes('SAVE_SHOP'),
  SHOP_LINE_CHANGE_STATUS: createRequestTypes('SHOP_LINE_CHANGE_STATUS'),
  SHOP_LINE_DELETE: createRequestTypes('SHOP_LINE_DELETE'),
}

// Lottery Box
export const LotteryBoxActionType = {
  FETCH_LOTTERY_BOX: createRequestTypes('FETCH_LOTTERY_BOX'),
  ORDER_LOTTERY_BOX: createRequestTypes('ORDER_LOTTERY_BOX'),
  FETCH_LOTTERY_BOX_MORE: 'FETCH_LOTTERY_BOX_MORE',
  ADD_LOTTERY_BOX_TO_CART: 'ADD_LOTTERY_BOX_TO_CART',
  REMOVE_LOTTERY_BOX_TO_CART: 'REMOVE_LOTTERY_BOX_TO_CART',
  CLEAR_CART_LOTTERY_BOX: 'CLEAR_CART_LOTTERY_BOX',
  INIT_LOTTERY_LOTTERY_BOX: 'INIT_LOTTERY_LOTTERY_BOX',
}

export const NotificationActionType = {
  INIT_NOTIFICATION: 'INIT_NOTIFICATION',
  SET_NOTIFICATION: 'SET_NOTIFICATION',
  PUSH_NOTIFICATION: 'PUSH_NOTIFICATION',
  READ_NOTIFICATION: 'READ_NOTIFICATION',
}
