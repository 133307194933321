import { _TOKEN_ } from "const"
// import { createSelector  } from 'reselect'
export const AuthSelector = {
  state: state => state.auth,
  token: state => AuthSelector.state(state)[_TOKEN_],
}

export const CommonSelector = {
  common: ({ common }) => common,
  drawDateList: state => CommonSelector.common(state).drawDateList,
  bankList: state => CommonSelector.common(state).bankList,
  showMenuBar: state => CommonSelector.common(state).showMenuBar,
  swIgnore: state => CommonSelector.common(state).swIgnore,
  swUpdate: state => CommonSelector.common(state).swUpdate,
  register: state => CommonSelector.common(state).register,
  loadingCentral: state => CommonSelector.common(state).loadingCentral,
}

export const AgentSelector = {
  agent: ({ agent }) => agent,
  agentDetail: state => AgentSelector.agent(state).detail,
  minorOrderInfo: state => AgentSelector.agent(state).minorOrderInfo,
  minorList: state => AgentSelector.agent(state).minorList,
  token: state => AgentSelector.agentDetail(state)?.token ?? 0,
}

export const LotterySelector = {
  lottery: ({ lottery }) => lottery.lotterySearch,
  loading: state => LotterySelector.lottery(state).loading,
  latestCriteria: state => LotterySelector.lottery(state).latestCriteria,
  cart: state => LotterySelector.lottery(state).cart,
  lotteryWinner: state => LotterySelector.lottery(state).lotteryWinner,
  lotteryGroupItem: state => LotterySelector.lottery(state).groupSummary,
  feeAgentList: state => LotterySelector.lottery(state).feeAgentList,
  numberSet: state => LotterySelector.lottery(state).numberSet,
  modalFrontDigitState: state => LotterySelector.lottery(state).modalFrontDigitState,
}

export const LotteryBoxSelector = {
  lottery: ({ lottery }) => lottery.lotteryBox,
  lotteries: state => LotteryBoxSelector.lottery(state).lotteries,
  loading: state => LotteryBoxSelector.lottery(state).loading,
  paginable: state => ({ ...LotteryBoxSelector.lottery(state).paginable }),
  total: state => LotteryBoxSelector.paginable(state).total,
  cart: state => LotteryBoxSelector.lottery(state).cart,
}

export const OrderSelector = {
  order: ({ order }) => order,
  loading: state => OrderSelector.order(state).loading,
  items: state => OrderSelector.order(state).items,
  totalItem: state => OrderSelector.order(state).totalItem,
  orderDetail: state => OrderSelector.order(state).orderDetail,

  filterTextAll: state => OrderSelector.order(state).orderList.filterTextAll,
  searchFilter: state => OrderSelector.order(state).orderList.searchFilter,
  paginable: state => OrderSelector.order(state).orderList.paginable,
  tableData: state => OrderSelector.order(state).orderList.tableData,
}

export const ShopSelector = {
  state: state => state.shop,
  loading: state => ShopSelector.state(state).loading,
  shopData: state => ShopSelector.state(state).shopData,
}

export const NotificationSelector = {
  state: state => state.notification,
  notifications: state => NotificationSelector.state(state).notifications,
}