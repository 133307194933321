import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import Settings from "./Settings";
import Auth from "./Auth";
import Notes from "./Notes";
import Contact from "./Contact";
import Common from "./Common";
import Agent from "./Agent";
import Lottery from "./Lottery";
import LotteryBox from "./LotteryBox";
import Customer from "./Customer";
import Order from "./Order";
import DashBoard from "./DashBoard";
import Shop from "./Shop";
import Notification from "./Notification";

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    notes: Notes,
    contact: Contact,
    common: Common,
    agent: Agent,
    // lottery: Lottery,
    lottery: combineReducers({
      lotterySearch: Lottery,
      lotteryBox: LotteryBox,
    }),
    customer: Customer,
    order: Order,
    dashBoard: DashBoard,
    shop: Shop,
    notification: Notification,
});

export default createRootReducer
